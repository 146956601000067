import React from 'react';
import { Handle, Position } from 'reactflow';
import { IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';

interface Props {
    data: any;
}

function InviteNewPartnerNode({ data }: Props) {
    const { push } = useHistory();
    return (
        <Paper
            sx={{
                backgroundColor: 'white',
                width: 275,
                height: 225,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Handle type="target" position={Position.Top} />
            <IconButton
                onClick={() => {
                    push(`/invite-organization/${data.context.organization}`);
                }}
            >
                <AddIcon
                    sx={{
                        fontSize: 50,
                    }}
                />
            </IconButton>
        </Paper>
    );
}

export default InviteNewPartnerNode;
