// AdminContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import checkAdminStatus from '../api/Auth/checkAdminStatus';

interface AdminContextType {
    isAdmin: boolean;
    pendingOrders: number;
    pendingMarketplaceResellRequests: number;
}

const AdminContext = createContext<AdminContextType>({
    isAdmin: false,
    pendingOrders: 0,
    pendingMarketplaceResellRequests: 0,
});

interface AdminProviderProps {
    children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
    const [adminData, setAdminData] = useState<AdminContextType>({
        isAdmin: false,
        pendingOrders: 0,
        pendingMarketplaceResellRequests: 0,
    });

    useEffect(() => {
        const fetchAdminStatus = async () => {
            try {
                if (window.location.href.includes('/unauthorized')) {
                    return;
                }

                const response = await checkAdminStatus();
                setAdminData({
                    isAdmin: response.status === 200 && response.data.is_global_admin,
                    pendingOrders: response.data.notifications.pending_orders,
                    pendingMarketplaceResellRequests:
                        response.data.notifications.pending_marketplace_resell_requests,
                });
            } catch (error) {
                setAdminData({
                    isAdmin: false,
                    pendingOrders: 0,
                    pendingMarketplaceResellRequests: 0,
                });
            }
        };

        fetchAdminStatus();
    }, []);

    return <AdminContext.Provider value={adminData}>{children}</AdminContext.Provider>;
};

export const UseAdminStatus = () => useContext(AdminContext);
