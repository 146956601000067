import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Link,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { saveFormSubmission } from 'api';
import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import { useNavigationContext } from 'Layouts/Navigation/NavigationContext';
import { cloneDeep, isEmpty, noop } from 'lodash';
import { useState } from 'react';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import { StringParam, useQueryParams } from 'use-query-params';
import {
    Asset,
    Form,
    getHtml,
    QuestionType,
    somethingWentWrong,
    Submission,
    SubmissionQuestion,
} from 'utils';
import AssessmentFormPreview from './AssessmentFormPreview';
import AssessmentSubmissionPreview from './AssessmentSubmissionPreview';

export interface AssessmentSubmissionProps {
    form: Form;
    submission: Submission;
    onReadyToSubmit: () => void;
    onSubmitCancelled: () => void;
    onFormExited: () => void;
    expanded?: boolean;
}

const AssessmentSubmission = ({
    form,
    submission,
    onReadyToSubmit,
    onSubmitCancelled,
    onFormExited,
    expanded,
}: AssessmentSubmissionProps) => {
    const [expandedDescription, setExpandedDescription] = useState(false);
    const { blockRedirection } = useNavigationContext();

    const totalQuestions = form.sections
        .map((section) => section.questions.length)
        .reduce((sum, noOfQuestions) => sum + noOfQuestions, 0);

    // As we have multiple section and each section having multiple questions
    // we will be keeping the track of current question in following acgive indexes
    const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
    // When user confirms that he is ready we can start the form
    const [query] = useQueryParams({
        start: StringParam,
    });

    const [formStarted, setFormStarted] = useState<boolean>(query['start'] ? true : false);
    const [readyToPreview, setReadyToPreview] = useState<boolean>(false);
    // The current active answer
    const [answer, setAnswer] = useState<string>('');
    const [answerChoice, setAnswerChoice] = useState<string>('');
    // All the submitted answers so far for this assessment form
    const [submittedAnswers, setSubmittedAnswers] = useState<Array<SubmissionQuestion>>([]);

    // Loaders
    const [showAnsweringLoader, setShowAnsweringLoader] = useState<boolean>(false);

    const theme = useTheme();
    const jumpToQuestion = (selectedQuestionUuid: string) => {
        form.sections.forEach((section, sectionIndex) => {
            let questionIndex = section.questions.findIndex(
                (question) => question.uuid == selectedQuestionUuid,
            );
            if (questionIndex >= 0) {
                setActiveQuestionIndex(questionIndex);
                setActiveSectionIndex(sectionIndex);
                setReadyToPreview(false);
                return;
            }
        });
    };

    const goToPreviousQuestion = () => {
        onSubmitCancelled();
        // Already on the first question, so cant go back anymore
        if (activeSectionIndex == 0 && activeQuestionIndex == 0) {
            return;
        }

        setAnswer('');
        setAnswerChoice('');

        // Already on the first question of current section
        // means we need to go back to  last question of previous section
        if (activeQuestionIndex == 0) {
            let previousSectionIndex = activeSectionIndex - 1;
            // Go to previous section
            setActiveSectionIndex(previousSectionIndex);
            // Set active question as last question
            setActiveQuestionIndex(form.sections[previousSectionIndex].questions.length - 1);

            return;
        }

        // Otherwise just go to the prev question
        setActiveQuestionIndex(activeQuestionIndex - 1);
    };

    const goToNextQuestion = () => {
        // Already on the last question, so cant go forward anymore
        let lastSection = form.sections[form.sections.length - 1];

        if (
            (activeSectionIndex == form.sections.length - 1 &&
                activeQuestionIndex == lastSection.questions.length - 1) ||
            totalQuestions == submittedAnswers.length
        ) {
            setReadyToPreview(true);
            onReadyToSubmit();
            return;
        }

        setAnswer('');
        setAnswerChoice('');

        // Already on the last question of current section
        // means we need to go forward to first question of next section
        if (activeQuestionIndex == form.sections[activeSectionIndex].questions.length - 1) {
            let nextSectionIndex = activeSectionIndex + 1;
            // Go to next section
            setActiveQuestionIndex(0);
            setActiveSectionIndex(nextSectionIndex);
            // Set active question as first question
            return;
        }

        // Otherwise just go to the next question
        setActiveQuestionIndex(activeQuestionIndex + 1);
    };

    const saveAnswer = () => {
        const question = form.sections[activeSectionIndex].questions[activeQuestionIndex];

        if (
            answer == '' &&
            submittedAnswers.find((submittedAnswer) => submittedAnswer.question == question.uuid)
                ?.answer
        ) {
            // Answer was not changed
            // Go to next question
            goToNextQuestion();
            return;
        }

        if (form.uuid && submission.uuid && question.uuid && answer.trim() != '') {
            setShowAnsweringLoader(true);
            saveFormSubmission(form.uuid, submission.uuid, [
                {
                    question: question.uuid,
                    answer: answer,
                },
            ])
                .then(() => {
                    // As the backend successfully saved the answer we will update the answer in
                    // submitted answers which we use at the final preview screen
                    let updatedSubmittedAnswers = cloneDeep(submittedAnswers);

                    // Check if this question was already answered
                    let alreadyAnswereQuestion = updatedSubmittedAnswers.find(
                        (submittedQuestion) => submittedQuestion.question == question.uuid,
                    );
                    // If yes then update the answer
                    if (alreadyAnswereQuestion) {
                        alreadyAnswereQuestion.answer = answer.trim();
                        alreadyAnswereQuestion.choice = answerChoice?.trim() || null;
                    } else {
                        // New answer pushed to submitted answers
                        updatedSubmittedAnswers.push({
                            question: question.uuid || '',
                            answer: answer.trim(),
                            choice: answerChoice?.trim() || null,
                        });
                    }
                    // Update submitted answers
                    setSubmittedAnswers(updatedSubmittedAnswers);
                    // Go to next question
                    goToNextQuestion();
                })
                .catch(() => somethingWentWrong())
                .finally(() => setShowAnsweringLoader(false));
        }
    };

    const mainHeight = expandedDescription ? '100%' : '64px';
    const displayProp = expanded ? 'none' : 'block';

    return (
        <Container maxWidth="md">
            {/* Show assessment preview before the actual exam form is started */}
            {!formStarted && !readyToPreview && (
                <AssessmentFormPreview
                    form={form}
                    submission={submission}
                    onFormExited={onFormExited}
                    onFormSubmissionStarted={() => {
                        setFormStarted(true);
                        blockRedirection(true);
                    }}
                />
            )}
            {/* Form has started which means user clicked on begin and proceeded to next step */}
            {formStarted && !readyToPreview && (
                <Box my={14}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '5px 5px 0 0',
                            color: theme.palette.common.white,
                            display: 'inline-block',
                            padding: theme.spacing(2, 4),
                            fontSize: 13,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                        }}
                    >
                        Section {activeSectionIndex + 1}
                    </Box>
                    <Paper>
                        <Box px={8} py={12}>
                            <Box
                                textAlign="center"
                                display="flex"
                                flexDirection="column"
                                style={{ gap: 6 }}
                                mb={10}
                            >
                                <Typography
                                    sx={{
                                        arginBottom: theme.spacing(5),
                                        color: theme.palette.primary.main,
                                    }}
                                    variant="h6"
                                >
                                    {form.sections[activeSectionIndex].name}
                                </Typography>
                                {form.sections[activeSectionIndex]?.asset && (
                                    <AssetPreviewCard
                                        key={activeSectionIndex}
                                        asset={form.sections[activeSectionIndex].asset as Asset}
                                        altTitle={form.sections[activeSectionIndex].name}
                                        onAssetRemoved={noop}
                                        disableRemove={true}
                                        fullwidth={true}
                                    />
                                )}
                                <Box
                                    sx={{
                                        height: mainHeight,
                                        overflow: 'hidden',
                                        position: 'relative',
                                        marginBottom: theme.spacing(10),
                                        textAlign: 'left',
                                        '&:after': {
                                            display: displayProp,
                                            content: '""',
                                            position: 'absolute',
                                            zIndex: 1,
                                            bottom: 0,
                                            left: 0,
                                            pointerEvents: 'none',
                                            backgroundImage:
                                                'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)',
                                            width: '100%',
                                            height: 30,
                                        },
                                    }}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getHtml(
                                                form.sections[activeSectionIndex].description || '',
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box textAlign="right">
                                    <Link
                                        href="#"
                                        onClick={(e: SyntheticEvent) => {
                                            e.preventDefault();
                                            setExpandedDescription((prevExpanded) => !prevExpanded);
                                        }}
                                    >
                                        {`show ${expandedDescription ? 'less' : 'more'}`}
                                    </Link>
                                </Box>
                            </Box>

                            <Paper elevation={2}>
                                <Box my={10} p={12} alignItems="left">
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontSize: 14,
                                            fontWeight: 600,
                                            lineHeight: '20px',
                                            marginBottom: theme.spacing(8),
                                        }}
                                    >
                                        Question {activeQuestionIndex + 1}
                                    </Typography>
                                    {form.sections[activeSectionIndex].questions[
                                        activeQuestionIndex
                                    ].asset && (
                                        <Box mb={8}>
                                            <AssetPreviewCard
                                                asset={
                                                    form.sections[activeSectionIndex].questions[
                                                        activeQuestionIndex
                                                    ].asset as Asset
                                                }
                                                altTitle={
                                                    form.sections[activeSectionIndex].questions[
                                                        activeQuestionIndex
                                                    ].question
                                                }
                                                onAssetRemoved={noop}
                                                disableRemove={true}
                                                fullwidth={true}
                                            />
                                        </Box>
                                    )}
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            marginBottom: theme.spacing(8),
                                        }}
                                    >
                                        {
                                            form.sections[activeSectionIndex].questions[
                                                activeQuestionIndex
                                            ].question
                                        }
                                    </Typography>
                                    {/* Text question answer */}
                                    {form.sections[activeSectionIndex].questions[
                                        activeQuestionIndex
                                    ].type == QuestionType.Text && (
                                        <TextField
                                            key={`${activeQuestionIndex} ${activeSectionIndex}`}
                                            label="Your Answer"
                                            multiline={true}
                                            maxRows={35}
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={
                                                submittedAnswers.find(
                                                    (submittedAnswer) =>
                                                        submittedAnswer.question ==
                                                        form.sections[activeSectionIndex].questions[
                                                            activeQuestionIndex
                                                        ].uuid,
                                                )?.answer || answer
                                            }
                                            onChange={(e) => {
                                                setAnswer(e.target.value || '');
                                                setAnswerChoice('');
                                            }}
                                        />
                                    )}

                                    {/* Multiple choice question answer */}
                                    {[
                                        QuestionType.MultipleChoiceSingleSelect,
                                        QuestionType.MultipleChoiceMultiSelect,
                                    ].some(
                                        (questionType) =>
                                            questionType ===
                                            form.sections[activeSectionIndex].questions[
                                                activeQuestionIndex
                                            ].type,
                                    ) && (
                                        <FormControl component="fieldset" sx={{ width: '100%' }}>
                                            <RadioGroup
                                                aria-label="gender"
                                                name="customized-radios"
                                                value={answer}
                                            >
                                                {form.sections[activeSectionIndex].questions[
                                                    activeQuestionIndex
                                                ].choices?.map((choice) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={choice.uuid}
                                                            value={choice.text}
                                                            sx={{
                                                                padding: theme.spacing(6, 0),
                                                                '&:hover': {
                                                                    background:
                                                                        theme.palette
                                                                            .backgroundColor.hover,
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setAnswer(choice.text);
                                                                setAnswerChoice(choice.uuid);
                                                            }}
                                                            control={<Radio color="primary" />}
                                                            label={
                                                                <Box display="flex" pr={10}>
                                                                    <Box>
                                                                        <Typography
                                                                            variant="body2"
                                                                            sx={{
                                                                                fontSize: 16,
                                                                                lineHeight: '38px',
                                                                            }}
                                                                        >
                                                                            {choice.text}
                                                                        </Typography>
                                                                        {choice.media_url && (
                                                                            <img
                                                                                style={{
                                                                                    maxWidth: 150,
                                                                                    maxHeight: 150,
                                                                                }}
                                                                                src={
                                                                                    choice.media_url
                                                                                }
                                                                                alt={choice.text}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            }
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                </Box>
                            </Paper>

                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                                mt={2}
                            >
                                {(activeSectionIndex == 0 && activeQuestionIndex == 0) ||
                                totalQuestions == submittedAnswers.length ? (
                                    ''
                                ) : (
                                    <Box
                                        position="absolute"
                                        left={0}
                                        top="50%"
                                        sx={{ transform: 'translateY(-50%)' }}
                                    >
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => goToPreviousQuestion()}
                                        >
                                            <ArrowBackIos fontSize="small" /> Back
                                        </Button>
                                    </Box>
                                )}
                                {showAnsweringLoader ? (
                                    <>Saving your answer...</>
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={() => saveAnswer()}
                                            color="primary"
                                            disabled={
                                                isEmpty(
                                                    submittedAnswers.find(
                                                        (submittedAnswer) =>
                                                            submittedAnswer.question ==
                                                            form.sections[activeSectionIndex]
                                                                .questions[activeQuestionIndex]
                                                                .uuid,
                                                    )?.answer,
                                                ) && isEmpty(answer)
                                            }
                                        >
                                            {totalQuestions == submittedAnswers.length
                                                ? 'Update Answer'
                                                : 'Next Question'}
                                        </Button>
                                        <Box
                                            position="absolute"
                                            right={0}
                                            top="50%"
                                            sx={{ transform: 'translateY(-50%)' }}
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={() => goToNextQuestion()}
                                                color="primary"
                                            >
                                                Skip <ArrowForwardIos fontSize="small" />
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            )}
            {readyToPreview && (
                <AssessmentSubmissionPreview
                    form={form}
                    submittedAnswers={submittedAnswers}
                    onQuestionAnswerEdited={(questionUuid) => jumpToQuestion(questionUuid)}
                />
            )}
        </Container>
    );
};

export default AssessmentSubmission;
