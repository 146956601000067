import { RouteConfigProps } from '../../Layouts/routeconfig';
import LandingPage from './scenes/LandingPage';
import { Box } from '@mui/material';
import BlankLayout from '../../Layouts/BlankLayout';
import CreateOrganization from './scenes/CreateOrganization';

export const websiteRoutes: Array<RouteConfigProps> = [
    {
        exact: true,
        layout: Box,
        path: '/',
        private: false,
        is_auth_route: true,
        component: LandingPage,
    },
    {
        exact: true,
        path: '/invite-organization/:id',
        layout: BlankLayout,
        component: CreateOrganization,
    },
];
