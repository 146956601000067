import {
    Avatar,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/system';
import AttributeInput from 'components/FormControl/AttributeInput';
import MediaSelectionGroup from 'components/FormControl/MediaSelectionGroup';
import MovementPresets from 'components/FormControl/MovementPresets';
import { TextEditorInput } from 'components/Inputs';
import TypographyTitle from 'components/Typography/TypographyTitle';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { SetAttributeType, yupSchemas } from 'utils';
import {
    Attribute,
    AttributeCategory,
    AttributeValue,
    MovementPreset,
    PartialUploadList,
} from 'utils/types';
import { FormikOnChange } from '.';
import { EncodingOptionsPayload, Media } from '../../app.types';
import { OrganizationsContext } from '../../contexts/OrganizationsContext';

export interface CreateMovementFormProps {
    /** title The title of the form */
    title?: string;
    /** assets An array of attributes to display in the attribute selection */
    attributes: Array<Attribute>;
    /** accessToken The access token to use with the xhr request to upload files. */
    accessToken: string;
    /** uploads A partial upload list to display in the form */
    uploads: PartialUploadList;
    /** media A list of media that has been attached to the movement **/
    media: Media[];
    /** onSubmit Submit the form */
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void;
    /** onChange Submit the form */
    onChange: (isValid: boolean, dirty: boolean, values: FormikValues) => void;
    /** onFileSelected Submit the form */
    onFileSelected: (file: File) => void;
    /** onAttributeValuesChanged */
    onAttributeValuesChanged: (values: AttributeValue[]) => void;
    /** onMediaDeleteClicked Callback for when the add button is clicked */
    onMediaDeleteClicked: (media: Media) => void;

    onMediaAdded: (media: Media) => void;
    formRef: React.Ref<FormikProps<FormikValues>>;

    defaultName?: string | null;

    defaultOrganization?: string | null;
    onEncodingOptionsSelected?: (fileName: string, encodingOptions: EncodingOptionsPayload) => void;
}

export default function CreateMovementForm({
    attributes,
    onMediaDeleteClicked,
    onSubmit,
    onChange,
    onAttributeValuesChanged,
    onFileSelected,
    uploads,
    formRef,
    onMediaAdded,
    title = '',
    defaultName = '',
    media = [],
    defaultOrganization = null,
    onEncodingOptionsSelected = () => {},
}: CreateMovementFormProps): ReactElement {
    const theme = useTheme();
    const { organizations } = useContext(OrganizationsContext);
    const [selectedPresets, setSelectedPresets] = useState<MovementPreset>({
        [SetAttributeType.Reps as string]: null,
    });
    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([]);

    const handleFileSelected = (file: File) => {
        onFileSelected(file);
    };

    const handleAttributeValuesChanged = (attributeValues: AttributeValue[]) => {
        setAttributeValues(attributeValues);
        onAttributeValuesChanged(attributeValues);
    };

    const getDefaultOrganization = useCallback(() => {
        if (defaultOrganization) {
            return defaultOrganization;
        }
        if (organizations && organizations.length > 0) {
            return organizations[organizations.length - 1].uuid;
        }
        return '';
    }, [defaultOrganization, organizations]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                ...yupSchemas.createMovement.getDefault(),
                ...{ name: defaultName },
                organization: getDefaultOrganization(),
            }}
            validationSchema={yupSchemas.createMovement}
            onSubmit={(values, formikHelpers) => {
                // Pass selected presets along with the formik values
                values.movement_presets = selectedPresets;
                onSubmit(values, formikHelpers);
            }}
            enableReinitialize={true}
        >
            {({ values, handleChange, errors, setFieldValue, setErrors }) => {
                return (
                    <Form style={{ width: '100%' }}>
                        <FormikOnChange onChange={onChange} />
                        <TypographyTitle sx={{ marginBottom: theme.spacing(1) }}>
                            {title}
                        </TypographyTitle>
                        <Paper>
                            <Box mt={5} mb={5} padding={5}>
                                <FormControl
                                    fullWidth
                                    sx={{ width: '100%', marginBottom: theme.spacing(1) }}
                                    error={Boolean(errors.organization)}
                                >
                                    <InputLabel id="demo-simple-select-label">
                                        Select Organization
                                    </InputLabel>
                                    <Select
                                        label="Select Organization"
                                        id="demo-simple-select"
                                        name="organization"
                                        value={values.organization}
                                        onChange={handleChange}
                                    >
                                        {organizations.map((o) => {
                                            return (
                                                <MenuItem key={o.uuid} value={o.uuid}>
                                                    <Box
                                                        sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={o.image_urls['avatar'] ?? ''}
                                                            alt={o.name}
                                                            variant="rounded"
                                                            sx={{
                                                                marginRight: 6,
                                                                display: 'inline-flex',
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                display: 'inline-flex',
                                                            }}
                                                        >
                                                            {o.name}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>

                                    <FormHelperText>{errors.organization as string}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Paper>
                        <Paper>
                            <Box mt={5} mb={5} padding={5}>
                                <TextField
                                    sx={{ width: '100%', marginBottom: theme.spacing(1) }}
                                    label="Movement Name"
                                    id="name"
                                    name="name"
                                    variant="outlined"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name as string}
                                />
                            </Box>
                        </Paper>
                        <Paper>
                            <Grid
                                container
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    padding: 5,
                                    justifyContent: 'center',
                                }}
                            >
                                <Box>
                                    <MediaSelectionGroup
                                        onFileSelected={handleFileSelected}
                                        onMediaSelected={(media) => onMediaAdded(media)}
                                        onMediaDeleteClicked={onMediaDeleteClicked}
                                        onEncodingOptionsSelected={onEncodingOptionsSelected}
                                        uploads={uploads}
                                        media={media}
                                        assets={[]}
                                        showCoverOptions={false}
                                        organization={values.organization}
                                    />
                                </Box>
                            </Grid>
                        </Paper>
                        <Paper>
                            <Box padding={5}>
                                <TextEditorInput
                                    label="Description (optional)"
                                    placeholder="Enter description here"
                                    defaultValue={values.description || ''}
                                    onChange={(newDescription) => {
                                        setFieldValue('description', newDescription);
                                    }}
                                />
                            </Box>
                        </Paper>
                        {Boolean(errors.description) && (
                            <FormHelperText>{errors.description as string}</FormHelperText>
                        )}
                        <Paper>
                            <Box mt={5} mb={5} padding={5}>
                                <MovementPresets
                                    value={selectedPresets}
                                    onChange={setSelectedPresets}
                                />
                            </Box>
                        </Paper>
                        <Paper>
                            <Box sx={{ padding: 5 }}>
                                <AttributeInput
                                    errors={errors}
                                    attributeCategory={AttributeCategory.Movement}
                                    setErrors={setErrors}
                                    attributes={attributes}
                                    value={attributeValues}
                                    onChange={(values) => handleAttributeValuesChanged(values)}
                                />
                            </Box>
                        </Paper>
                    </Form>
                );
            }}
        </Formik>
    );
}
