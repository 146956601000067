import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import {
    CalendarEventListItem,
    Form,
    Individual,
    Organization,
    PaginationLink,
    Submission,
} from '../../utils';

export interface SubmissionsResponse {
    data: Submission[];
    links: PaginationLink;
    meta: {
        path: string;
        per_page: number;
        current_page: number;
        total: number;
    };
}

export interface AssignmentsResponse {
    data: CalendarEventListItem[];
    links: PaginationLink;
    meta: {
        path: string;
        per_page: number;
        current_page: number;
        total: number;
    };
}

export interface OrganizationsResponse {
    data: Organization[];
    links: PaginationLink;
    meta: {
        path: string;
        per_page: number;
        current_page: number;
        total: number;
    };
}

export interface IndividualsResponse {
    data: Individual[];
    links: PaginationLink;
    meta: {
        path: string;
        per_page: number;
        current_page: number;
        total: number;
    };
}

export interface AssignFormRequestParams {
    start_date_time: string;
    end_date_time: string | null;
    individual?: string;
    group?: string;
    timezone: string;
}

export interface SubmissionListParams {
    page?: number;
    per_page?: number;
    'filter[organization_uuid]'?: string;
    'filter[individual_uuid]'?: string;
    'filter[form_title]'?: string;
    sort?: string;
}

export interface AssignmentListParams {
    page?: number;
    per_page?: number;
    'filter[organization_uuid]'?: string;
    'filter[individual_uuid]'?: string;
    'filter[form_title]'?: string;
    sort?: string;
}

export interface OrgListParams {
    'filter[name]'?: string;
}

export interface IndividualListParams {
    'filter[name]'?: string;
}

export const assignFormToCalendar = (
    id: string,
    params: AssignFormRequestParams,
): Promise<AxiosResponse<void>> => {
    return Request.post(`/data/forms/${id}/assign`, params);
};

export const duplicateForm = (
    id: string,
    params: { organization: string },
): Promise<AxiosResponse<Form>> => {
    return Request.post(`/data/forms/${id}/duplicate`, params);
};

export const getOrgsForCoach = (
    params: OrgListParams,
): Promise<AxiosResponse<OrganizationsResponse>> => {
    return Request.get(`/data/submissions/coach/organizations`, { params });
};

export const getIndividualsForCoach = (
    params: IndividualListParams,
): Promise<AxiosResponse<IndividualsResponse>> => {
    return Request.get(`/data/submissions/coach/individuals`, { params });
};

export const getSubmissionsForCoach = (
    params: SubmissionListParams,
): Promise<AxiosResponse<SubmissionsResponse>> => {
    return Request.get(`/data/submissions/coach/submissions`, { params });
};

export const getAssignmentsForCoach = (
    params: AssignmentListParams,
): Promise<AxiosResponse<AssignmentsResponse>> => {
    return Request.get(`/data/submissions/coach/assignments`, { params });
};
