import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { confirmViaDialog } from 'components/Dialogs/ConfirmationDialog';
import {
    BandOptions,
    RPEOptions,
    SessionMovement,
    SetAttributeType,
    TimeDisplayFormat,
} from 'utils';
import { SessionTimeInput } from 'components/Inputs';
import { LogInput, LogSelect } from 'components/FormControl';
import useTheme from '@mui/material/styles/useTheme';

export interface SessionMovementRowProps {
    index: number;
    goalSet: SessionMovement;
    applicableCriterias: Array<SetAttributeType>;
    onSessionMovementGoalValueChanged: (
        updatedSessionMovement: SessionMovement,
        updatedGoalCriteria: SetAttributeType,
        newValue: any,
    ) => void;
    onSessionMovementRemoved: (removedSessionMovement: SessionMovement) => void;
    readOnly?: boolean | undefined;
}

const SessionMovementRow = ({
    index,
    goalSet,
    applicableCriterias,
    onSessionMovementRemoved,
    onSessionMovementGoalValueChanged,
    readOnly = false,
}: SessionMovementRowProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                '& .MuiInputBase-root': {
                    borderBottom: '1px solid transparent',
                    '&:hover': {
                        borderBottom: `1px solid ${theme.palette.text.secondary}`,
                    },
                    '&:focus': {
                        borderBottom: `1px solid ${theme.palette.text.secondary}`,
                    },
                },
            }}
            key={goalSet.pivot?.uuid}
        >
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={10}
                    sx={{
                        padding: theme.spacing(2, 8),
                        [theme.breakpoints.down('md')]: {
                            padding: theme.spacing(2, 4),
                        },
                    }}
                >
                    <Box sx={{ width: theme.spacing(25) }}>
                        <Typography variant="body2">{index + 1}</Typography>
                    </Box>

                    {/* Reps column */}
                    {applicableCriterias.indexOf(SetAttributeType.Reps) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.reps}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Reps,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Load column */}
                    {applicableCriterias.indexOf(SetAttributeType.Load) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.load_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.load_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Load,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Time column */}
                    {applicableCriterias.indexOf(SetAttributeType.Time) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <SessionTimeInput
                                key={`log-time-input-${goalSet.pivot?.time_display_format || ''}`}
                                defaultValueInMilliseconds={goalSet.pivot?.time_value || 0}
                                onTimeChanged={(durationInMilliseconds: number) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Time,
                                        durationInMilliseconds || 0,
                                    );
                                }}
                                format={
                                    (goalSet.pivot?.time_display_format as TimeDisplayFormat) ||
                                    TimeDisplayFormat.Common
                                }
                            />
                        </Box>
                    )}

                    {/* Distance column */}
                    {applicableCriterias.indexOf(SetAttributeType.Distance) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.distance_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.distance_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Distance,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Range of Motion column */}
                    {applicableCriterias.indexOf(SetAttributeType.RangeOfMotion) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.range_of_motion_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.range_of_motion_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.RangeOfMotion,
                                        newValue,
                                    );
                                }}
                                max={360}
                            />
                        </Box>
                    )}

                    {/* Speed column */}
                    {applicableCriterias.indexOf(SetAttributeType.Speed) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.speed_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.speed_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Speed,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Acceleration column */}
                    {applicableCriterias.indexOf(SetAttributeType.Acceleration) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.acceleration_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.acceleration_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Acceleration,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Power column */}
                    {applicableCriterias.indexOf(SetAttributeType.Power) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.power_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.power_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Power,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* RSI column */}
                    {applicableCriterias.indexOf(SetAttributeType.RSI) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.rsi_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.rsi_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.RSI,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* RPM column */}
                    {applicableCriterias.indexOf(SetAttributeType.RPM) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.rpm_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.rpm_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.RPM,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Force column */}
                    {applicableCriterias.indexOf(SetAttributeType.Force) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.force_unit || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.force_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Force,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Body Side column */}
                    {applicableCriterias.indexOf(SetAttributeType.BodySide) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogSelect
                                key={`log-time-input-${goalSet.pivot?.body_side || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.body_side}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.BodySide,
                                        newValue,
                                    );
                                }}
                                options={{
                                    left: 'Left',
                                    right: 'Right',
                                    both: 'Both',
                                }}
                            />
                        </Box>
                    )}

                    {/* RPE column */}
                    {applicableCriterias.indexOf(SetAttributeType.RPE) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogSelect
                                key={`log-time-input-${goalSet.pivot?.rpe_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.rpe_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.RPE,
                                        parseInt(newValue, 10),
                                    );
                                }}
                                options={RPEOptions}
                            />
                        </Box>
                    )}
                    {/* Band column */}
                    {applicableCriterias.indexOf(SetAttributeType.Band) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogSelect
                                key={`log-time-input-${goalSet.pivot?.band_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.band_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Band,
                                        parseInt(newValue, 10),
                                    );
                                }}
                                options={BandOptions}
                            />
                        </Box>
                    )}

                    {/* Rest column */}
                    {applicableCriterias.indexOf(SetAttributeType.Rest) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.rest_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.rest_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.Rest,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* GPS Player Load column */}
                    {applicableCriterias.indexOf(SetAttributeType.GPSPlayerLoad) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.gps_player_load_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.gps_player_load_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.GPSPlayerLoad,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* GPS Acceleration Count column */}
                    {applicableCriterias.indexOf(SetAttributeType.GPSAccelCount) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.gps_accel_count_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.gps_accel_count_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.GPSAccelCount,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* GPS Deceleration Count column */}
                    {applicableCriterias.indexOf(SetAttributeType.GPSDecelCount) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                key={`log-time-input-${goalSet.pivot?.gps_decel_count_value || ''}`}
                                readOnly={readOnly}
                                defaultValue={goalSet.pivot?.gps_decel_count_value}
                                onValueChanged={(newValue: string) => {
                                    onSessionMovementGoalValueChanged(
                                        goalSet,
                                        SetAttributeType.GPSDecelCount,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Actions column */}
                    {readOnly ? (
                        <Box
                            sx={{
                                width: theme.spacing(30),
                                marginRight: theme.spacing(4),
                                [theme.breakpoints.down('md')]: {
                                    marginRight: 0,
                                },
                            }}
                        ></Box>
                    ) : (
                        <Box
                            sx={{
                                width: theme.spacing(30),
                                marginRight: theme.spacing(4),
                                [theme.breakpoints.down('md')]: {
                                    marginRight: 0,
                                },
                            }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ fontWeight: 700 }}
                            >
                                <IconButton
                                    onClick={async () => {
                                        if (
                                            await confirmViaDialog({
                                                confirmation: {
                                                    title: 'Are you sure?',
                                                    message: `This will delete set #${
                                                        index + 1
                                                    } from '${goalSet.name}'.`,
                                                },
                                            })
                                        ) {
                                            onSessionMovementRemoved(goalSet);
                                        }
                                    }}
                                    sx={{
                                        padddingTop: 0,
                                        padddingBottom: 0,
                                        [theme.breakpoints.down('md')]: {
                                            padding: 0,
                                        },
                                    }}
                                    size="large"
                                >
                                    <Cancel fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </Box>

                <Divider sx={{ width: theme.spacing(25) }} />
            </>
        </Box>
    );
};

export default SessionMovementRow;
