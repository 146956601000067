import axios, { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { SimplePaginateResponse } from '../../../app.types';
import { Crop, PerformanceDataUpload, RawDataPoint, SprintDataType } from '../community.types';

const uploadPerformanceData = (
    individual_id: string,
    file_name: string,
    key: string,
    data_type: SprintDataType,
    date: string,
    mass: number,
    height: number,
    min_velocity: number,
    tag: string = '',
): Promise<AxiosResponse<PerformanceDataUpload>> => {
    let data = {
        file_name,
        key,
        date,
        data_type,
        mass,
        height,
        min_velocity,
    } as any;
    if (tag) {
        data['tag'] = tag;
    }
    return Request.post(`/individuals/${individual_id}/performance`, data);
};

const getUploads = (
    individual_id: string,
    params: { page?: number; filter?: { tag?: Array<string> } } = {},
): Promise<AxiosResponse<SimplePaginateResponse<PerformanceDataUpload>>> => {
    return Request.get(`/individuals/${individual_id}/performance`, {
        params,
    });
};

const getUpload = (
    individual_id: string,
    upload_id: string,
): Promise<AxiosResponse<PerformanceDataUpload>> => {
    return Request.get(`/individuals/${individual_id}/performance/${upload_id}`);
};

const fetchSignedUrl = (url: string): Promise<AxiosResponse<Array<{ t: number; s: number }>>> => {
    return axios.get(url);
};

const getRawSessionData = (
    individual_id: string,
): Promise<
    AxiosResponse<
        Array<{ activity_date: string; signed_url: string; individual_uuid: string; uuid: string }>
    >
> => {
    return Request.get(`/individuals/${individual_id}/raw-session-data`);
};

const saveUpload = (
    individual_id: string,
    upload_id: string,
    payload: { date?: string | null; tag?: string | null; file_name?: string | null },
): Promise<AxiosResponse<PerformanceDataUpload>> => {
    return Request.patch(`/individuals/${individual_id}/performance/${upload_id}`, payload);
};

const deleteUpload = (
    individual_id: string,
    upload_id: string,
): Promise<AxiosResponse<PerformanceDataUpload>> => {
    return Request.delete(`/individuals/${individual_id}/performance/${upload_id}`);
};

const getAllCrops = (individual_id: string, upload_id: string): Promise<AxiosResponse<Crop[]>> => {
    return Request.get(`/individuals/${individual_id}/performance/${upload_id}/crops`);
};

const getAllCropsForExploration = (
    exploration_uuid: string,
    params: {
        after?: number;
        before?: number;
    },
): Promise<AxiosResponse<Crop[]>> => {
    return Request.get(`/explorations/${exploration_uuid}/crops`, { params });
};
const createCrop = (
    individual_id: string,
    upload_id: string,
    payload: { label: string; start_timestamp: number; end_timestamp: number },
): Promise<AxiosResponse<Crop>> => {
    return Request.post(`/individuals/${individual_id}/performance/${upload_id}/crops`, payload);
};

const deleteCrop = (
    individual_id: string,
    upload_id: string,
    crop_id: string,
): Promise<AxiosResponse<void>> => {
    return Request.delete(
        `/individuals/${individual_id}/performance/${upload_id}/crops/${crop_id}`,
    );
};

const updateCrop = (
    individual_id: string,
    upload_id: string,
    crop_id: string,
    data: { label: string; start_timestamp: number; end_timestamp: number },
): Promise<AxiosResponse<Crop>> => {
    return Request.put(
        `/individuals/${individual_id}/performance/${upload_id}/crops/${crop_id}`,
        data,
    );
};

const getRawUploadDataFromUrl = (url: string): Promise<AxiosResponse<RawDataPoint[]>> => {
    return axios.get(url);
};

const deleteSprint = (
    individual_id: string,
    upload_id: string,
    sprint_id: string,
): Promise<AxiosResponse<void>> => {
    return Request.delete(
        `/individuals/${individual_id}/performance/${upload_id}/sprints/${sprint_id}`,
    );
};

const saveSprint = (
    individual_id: string,
    upload_id: string,
    sprint_id: string,
    payload: { tag_id?: string | null },
): Promise<AxiosResponse<void>> => {
    return Request.put(
        `/individuals/${individual_id}/performance/${upload_id}/sprints/${sprint_id}`,
        payload,
    );
};

export {
    uploadPerformanceData,
    getUploads,
    getUpload,
    deleteUpload,
    saveUpload,
    saveSprint,
    deleteSprint,
    getRawUploadDataFromUrl,
    createCrop,
    deleteCrop,
    getAllCrops,
    updateCrop,
    getAllCropsForExploration,
    getRawSessionData,
    fetchSignedUrl,
};
