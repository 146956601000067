import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Handle, Position } from 'reactflow';
import { formatCurrency } from '../../../app.functions';
import { convertOrganizationLevelToLevel, convertRankLevelToRank } from '../networking.functions';
import { PartnerNodeAdvancedInfo, Rank } from '../networking.types';
import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { getNodeAdvancedInfo } from '../networking.api';
import { useParams } from 'react-router-dom';

function PartnerViewNode({
    data,
}: {
    data: {
        partnerId: string;
        rank: number;
        matchesFilter: boolean;
        label: string;
        levels: { [key: string]: number };
        leadership_rank: number;
        context: { organization?: string; ranks: Rank[] };
    };
}) {
    const { id } = useParams<{ id: string }>();
    const [open, setOpen] = useState(false);
    const [partnerData, setPartnerData] = useState<PartnerNodeAdvancedInfo | null>(null);

    const handleInfoClick = async () => {
        setOpen(true);
        try {
            const response = await getNodeAdvancedInfo({ id: id, partnerId: data.partnerId });
            setPartnerData(response.data);
        } catch (error) {
            console.error('Error fetching partner data:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setPartnerData(null);
    };

    return (
        <Paper
            sx={{
                backgroundColor: 'white',
                width: 275,
                height: 225,
                opacity: data.matchesFilter ? 1 : 0.25,
            }}
        >
            <Handle type="target" position={Position.Top} />
            <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
                <Box
                    sx={{ backgroundColor: blueGrey[200], textAlign: 'center', fontWeight: 'bold' }}
                >
                    <IconButton size="small" onClick={handleInfoClick}>
                        <InfoIcon />
                    </IconButton>
                    {data.label}
                </Box>
                <Box>
                    <TableContainer>
                        <Table size={'small'}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Current Rank</TableCell>
                                    <TableCell>
                                        <b>
                                            {convertRankLevelToRank(data.rank, data.context.ranks)}
                                        </b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Athletes</TableCell>
                                    <TableCell>{data.levels.athleteCount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Org Subscription</TableCell>
                                    <TableCell>
                                        {convertOrganizationLevelToLevel(
                                            data.levels.organizationSubscriptionLevel,
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Marketplace Sales</TableCell>
                                    <TableCell>
                                        {formatCurrency({
                                            amount: data.levels.marketplaceSales / 100,
                                            currency: 'USD',
                                        })}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Handle type="source" position={Position.Bottom} id="a" />
            <Dialog open={open} onClose={handleClose}>
                {partnerData && (
                    <>
                        <DialogTitle>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {partnerData?.organization?.image_urls?.['200'] ? (
                                        <img
                                            src={partnerData.organization.image_urls['200']}
                                            height="32"
                                            alt="logo"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <Box sx={{ marginLeft: '10px' }}>
                                        <span>{partnerData.organization.name}</span>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Box style={{ lineHeight: '0.7', marginTop: '4px' }}>
                                        <span style={{ fontSize: '0.6rem', fontWeight: 400 }}>
                                            {partnerData.organization.creator?.first_name}{' '}
                                            {partnerData.organization.creator?.last_name}
                                        </span>
                                    </Box>
                                    <Box style={{ lineHeight: '0.7' }}>
                                        <span style={{ fontSize: '0.6rem', fontWeight: 400 }}>
                                            {partnerData.organization.creator?.phone}
                                        </span>
                                    </Box>
                                    <Box style={{ lineHeight: '0.7' }}>
                                        <span style={{ fontSize: '0.6rem', fontWeight: 400 }}>
                                            {partnerData.organization.creator?.email}
                                        </span>
                                    </Box>
                                </Box>
                            </Box>
                            {partnerData.shipping_address && (
                                <Box sx={{ marginTop: '10px' }}>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{ fontSize: '0.7rem' }}
                                    >
                                        {partnerData.shipping_address.line1}
                                    </Typography>
                                    {partnerData.shipping_address.line2 && (
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{ fontSize: '0.7rem' }}
                                        >
                                            {partnerData.shipping_address.line2}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{ fontSize: '0.7rem' }}
                                    >
                                        {partnerData.shipping_address.city},{' '}
                                        {partnerData.shipping_address.state}{' '}
                                        {partnerData.shipping_address.postal_code}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{ fontSize: '0.7rem' }}
                                    >
                                        {partnerData.shipping_address.country}
                                    </Typography>
                                </Box>
                            )}
                        </DialogTitle>
                        <Divider />
                        <DialogContent style={{ minWidth: '400px' }}>
                            <Box>
                                <TableContainer>
                                    <Table
                                        size={'small'}
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                borderBottom: 'none',
                                                fontSize: '0.7rem',
                                                marginLeft: 0,
                                            },
                                        }}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Current Rank</TableCell>
                                                <TableCell>
                                                    <b>
                                                        {convertRankLevelToRank(
                                                            data.rank,
                                                            data.context.ranks,
                                                        )}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Athletes</TableCell>
                                                <TableCell>{data.levels.athleteCount}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Org Subscription</TableCell>
                                                <TableCell>
                                                    {convertOrganizationLevelToLevel(
                                                        data.levels.organizationSubscriptionLevel,
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Marketplace Sales</TableCell>
                                                <TableCell>
                                                    {formatCurrency({
                                                        amount: data.levels.marketplaceSales / 100,
                                                        currency: 'USD',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Commissions</TableCell>
                                                <TableCell>
                                                    {formatCurrency({
                                                        amount: partnerData.commissions / 100,
                                                        currency: 'USD',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Payouts</TableCell>
                                                <TableCell>
                                                    {formatCurrency({
                                                        amount: partnerData.payouts / 100,
                                                        currency: 'USD',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Organization Pod Subscriptions
                                                </TableCell>
                                                <TableCell>
                                                    {partnerData.active_pod_subscription_count_org}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Athlete Pod Subscriptions</TableCell>
                                                <TableCell>
                                                    {
                                                        partnerData.active_pod_subscription_count_athlete
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            {partnerData.sponsor?.organization && (
                                                <TableRow>
                                                    <TableCell>Sponsor</TableCell>
                                                    <TableCell>
                                                        {partnerData.sponsor.organization.name}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Paper>
    );
}

export default PartnerViewNode;
