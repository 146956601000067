import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGridPremium, GridSortModel } from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Organization } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import {
    GetOrgSubscriptionParams,
    getOrgSubscriptions,
    getUserSubscriptions,
    GetUserSubscriptionParams,
    getSubscriptionItemChangeRequests,
    GetSubscriptionItemChangeRequestParams,
    updateSubscriptionItemChangeRequest,
} from '../subscriptions.api';
import { OrgSubscription, UserSubscription } from '../subscriptions.types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import MenuItem from '@mui/material/MenuItem';
import { Subscription, SubscriptionItemChangeRequest } from '../../../../../app.types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(25% - 16px)',
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const SubscriptionList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [orgSubscriptions, setOrgSubscriptions] = useState<OrgSubscription[]>([]);
    const [orgSearchParams, setOrgSearchParams] = useState<GetOrgSubscriptionParams>({
        name: '',
        organization_uuid: '',
        stripe_status: 'active',
        sort: '-updated_at',
    });
    const [orgPaginationModel, setOrgPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [orgTotalRows, setOrgTotalRows] = useState(0);
    const orgSearchParamsRef = useRef(orgSearchParams);

    const [userSubscriptions, setUserSubscriptions] = useState<UserSubscription[]>([]);
    const [userSearchParams, setUserSearchParams] = useState<GetUserSubscriptionParams>({
        name: '',
        user_name: '',
        stripe_status: 'active',
        sort: '-updated_at',
    });
    const [userPaginationModel, setUserPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [userTotalRows, setUserTotalRows] = useState(0);
    const userSearchParamsRef = useRef(userSearchParams);

    const [changeRequests, setChangeRequests] = useState<SubscriptionItemChangeRequest[]>([]);
    const [changeRequestSearchParams, setChangeRequestSearchParams] = useState({
        organization_uuid: '',
        status: '',
        sort: '-created_at',
    });
    const [changeRequestPaginationModel, setChangeRequestPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [changeRequestTotalRows, setChangeRequestTotalRows] = useState(0);
    const changeRequestSearchParamsRef = useRef(changeRequestSearchParams);
    const [snackMessage, setSnackMessage] = useState<string>('');

    const dataGridSx = {
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
    };

    useEffect(() => {
        changeRequestSearchParamsRef.current = changeRequestSearchParams;
        orgSearchParamsRef.current = orgSearchParams;
        userSearchParamsRef.current = userSearchParams;
    }, [changeRequestSearchParams, orgSearchParams, userSearchParams]);

    useEffect(() => {
        // Fetch SubscriptionItemChangeRequests
        const params = {
            ...changeRequestSearchParamsRef.current,
            per_page: changeRequestPaginationModel.pageSize,
            page: changeRequestPaginationModel.page + 1,
        };
        getSubscriptionItemChangeRequests(params as GetSubscriptionItemChangeRequestParams)
            .then((response) => {
                setChangeRequests(response.data.data);
                setChangeRequestTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch change requests', error);
            });
    }, [changeRequestPaginationModel]);

    useEffect(() => {
        const params = {
            ...orgSearchParamsRef.current,
            per_page: orgPaginationModel.pageSize,
            page: orgPaginationModel.page + 1,
        };
        getOrgSubscriptions(params)
            .then((response) => {
                setOrgSubscriptions(
                    response.data.data.map((item: OrgSubscription) => ({ ...item, id: item.id })),
                );
                setOrgTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch subscriptions', error);
            });
    }, [orgPaginationModel]);

    useEffect(() => {
        const params = {
            ...userSearchParamsRef.current,
            per_page: userPaginationModel.pageSize,
            page: userPaginationModel.page + 1,
        };
        getUserSubscriptions(params)
            .then((response) => {
                setUserSubscriptions(response.data.data);
                setUserTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch user subscriptions', error);
            });
    }, [userPaginationModel]);

    const [editedRows, setEditedRows] = useState<{ [key: string]: any }>({});

    const handleFieldChange = (id: string, field: string, value: any) => {
        const originalRow = changeRequests.find((cr) => cr.uuid === id);
        const originalValue =
            originalRow && field in originalRow
                ? originalRow[field as keyof typeof originalRow]
                : undefined;

        setEditedRows((prev) => {
            const newRow = {
                ...prev[id],
                [field]: value === originalValue ? undefined : value,
            };

            if (!Object.values(newRow).some((v) => v !== undefined)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [id]: _, ...rest } = prev;
                return rest;
            }

            return {
                ...prev,
                [id]: newRow,
            };
        });
    };

    const handleUpdate = async (id: string) => {
        const editedRow = editedRows[id];
        const originalRow = changeRequests.find((cr) => cr.uuid === id);

        if (!editedRow && !originalRow) {
            setSnackMessage('No changes to update');
            return;
        }

        try {
            const updateData = {
                status: editedRow?.status || originalRow?.status,
                notes: editedRow?.notes || originalRow?.notes,
            };

            const res = await updateSubscriptionItemChangeRequest(id, updateData);

            if (res.status === 200) {
                setSnackMessage('Change request updated successfully');
                // Update the local state to reflect the changes
                setChangeRequests((prev) =>
                    prev.map((request) =>
                        request.uuid === id ? { ...request, ...updateData } : request,
                    ),
                );
            } else {
                setSnackMessage('Failed to update change request');
            }
        } catch (error) {
            console.error('Error updating change request:', error);
            setSnackMessage('Error updating change request');
        }

        // Clear the edited row from the state
        setEditedRows((prev) => {
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });
    };

    interface NotesProps {
        id: string;
        value: string;
        editedRows: { [key: string]: { notes?: string; [key: string]: any } };
        setEditedRows: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
    }

    const NotesCell = React.memo(({ id, value, editedRows, setEditedRows }: NotesProps) => {
        const [localValue, setLocalValue] = useState(editedRows[id]?.notes ?? value);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            setLocalValue(e.target.value);
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            e.stopPropagation();
            setEditedRows((prev) => ({
                ...prev,
                [id]: {
                    ...prev[id],
                    notes: localValue === value ? undefined : localValue,
                },
            }));
        };

        return (
            <TextField
                value={localValue}
                onChange={handleChange}
                onKeyDown={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onBlur={handleBlur}
                size="small"
                fullWidth
                sx={{
                    backgroundColor: 'white',
                    '& .MuiInputBase-root': {
                        height: '40px',
                    },
                }}
            />
        );
    });

    NotesCell.displayName = 'NotesCell';

    const changeRequestColumns: GridColDef[] = [
        {
            field: 'organization_name',
            headerName: 'Organization',
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return params.row.organization.name;
            },
        },
        {
            field: 'subscription_name',
            headerName: 'Subscription',
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return params.row.subscription.name;
            },
        },
        {
            field: 'subscription_item_name',
            headerName: 'Subscription Item',
            width: 200,
            sortable: false,
            renderCell: (params: any) => (
                <a
                    href={`https://dashboard.stripe.com/subscriptions/${params.row.subscription.stripe_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.row.subscription_item.name}
                </a>
            ),
        },
        { field: 'change_request_type', headerName: 'Change Request', width: 150, sortable: true },
        {
            field: 'change_request_reason',
            headerName: 'Reason',
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                {params.row.change_request_reason}
                            </span>
                        }
                        arrow
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'preventOverflow',
                                    options: {
                                        boundary: 'viewport',
                                    },
                                },
                            ],
                        }}
                        sx={{ maxWidth: 300 }} // Set a maximum width for the tooltip
                    >
                        <span
                            style={{
                                fontSize: '0.9rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '140px',
                                display: 'inline-block',
                                cursor: 'pointer',
                            }}
                        >
                            {params.row.change_request_reason}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Select
                    variant="outlined"
                    value={editedRows[params.id]?.status || params.value}
                    onChange={(e) =>
                        handleFieldChange(params.id as string, 'status', e.target.value)
                    }
                    size="small"
                    fullWidth
                >
                    <MenuItem value="requested">Requested</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
            ),
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: 300,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <NotesCell
                    id={params.id as string}
                    value={params.value}
                    editedRows={editedRows}
                    setEditedRows={setEditedRows}
                />
            ),
        },
        {
            field: 'contact',
            headerName: 'Contact',
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const { first_name, last_name, email, phone } = params.row.organization.creator;
                const fullName = `${first_name} ${last_name}`;
                const contactInfo = `Email: ${email}\nPhone: ${phone}`;

                const handleCopy = () => {
                    if (navigator.clipboard && navigator.clipboard.writeText) {
                        navigator.clipboard
                            .writeText(contactInfo)
                            .then(() => {
                                // Optionally, you can show a success message here
                                console.log('Contact info copied to clipboard');
                            })
                            .catch((err) => {
                                console.error('Failed to copy text: ', err);
                            });
                    } else {
                        // Fallback for browsers that don't support the Clipboard API
                        const textArea = document.createElement('textarea');
                        textArea.value = contactInfo;
                        document.body.appendChild(textArea);
                        textArea.select();
                        document.execCommand('copy');
                        document.body.removeChild(textArea);
                        console.log('Contact info copied to clipboard (fallback method)');
                    }
                };

                return (
                    <Tooltip
                        style={{ cursor: 'pointer' }}
                        title={
                            <React.Fragment>
                                {contactInfo}
                                <IconButton onClick={handleCopy} size="small" sx={{ ml: 1 }}>
                                    <ContentCopyIcon fontSize="inherit" />
                                </IconButton>
                            </React.Fragment>
                        }
                        arrow
                    >
                        <span>{fullName}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 200,
            sortable: true,
            valueFormatter: (params: any) => {
                const date = new Date(params * 1000); // Convert seconds to milliseconds
                const month = date.getMonth() + 1; // getMonth() is 0-indexed
                const day = date.getDate();
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleUpdate(params.id as string)}
                    disabled={!editedRows[params.id]}
                >
                    Update
                </Button>
            ),
        },
    ];

    const handleChangeRequestSearchParameterChange = (
        event:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string>,
    ) => {
        const { name, value } = event.target;
        setChangeRequestSearchParams((prevParams) => ({
            ...prevParams,
            [name]: value,
        }));
    };

    const handleChangeRequestSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...changeRequestSearchParams,
                organization_uuid: changeRequestSearchParams.organization_uuid || undefined,
                status:
                    changeRequestSearchParams.status === 'All'
                        ? undefined
                        : changeRequestSearchParams.status,
                page: changeRequestPaginationModel.page + 1,
                per_page: changeRequestPaginationModel.pageSize,
            };
            await getChangeRequestSearchResults(params as GetSubscriptionItemChangeRequestParams);
        } catch (error) {
            console.error(error);
        }
    };

    const getChangeRequestSearchResults = async (
        params: GetSubscriptionItemChangeRequestParams,
    ) => {
        const response = await getSubscriptionItemChangeRequests(params);
        if (Array.isArray(response.data.data)) {
            const updatedData = response.data.data.map((item) => ({
                ...item,
                notes:
                    editedRows[item.uuid]?.notes !== undefined
                        ? editedRows[item.uuid].notes
                        : item.notes,
            }));
            setChangeRequests(updatedData);
            setChangeRequestTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: change requests data is not an array');
        }
    };

    const handleChangeRequestSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        const fieldMapping: { [key: string]: string } = {
            organization_name: 'organization.name',
            subscription_name: 'subscription.name',
            subscription_item_name: 'subscription_item.name',
            change_request_type: 'change_request_type',
            created_at: 'created_at',
        };

        if (sortField) {
            sortField = fieldMapping[sortField] || sortField;

            if (sortOrder === 'desc') {
                sortField = `-${sortField}`;
            }
        }

        const params = {
            ...changeRequestSearchParams,
            sort: sortField,
            page: changeRequestPaginationModel.page + 1,
            per_page: changeRequestPaginationModel.pageSize,
        };

        await getChangeRequestSearchResults(params as GetSubscriptionItemChangeRequestParams);
    };

    const fetchOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchOrganizations('', 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orgColumns: GridColDef[] = [
        { field: 'organization_name', headerName: 'Organization', width: 150, sortable: true },
        {
            field: 'organization.pod_count',
            headerName: 'Org Pods',
            width: 150,
            sortable: false,
            renderCell: (params: GridRenderCellParams<OrgSubscription, any, any>) => (
                <span>{params.row.organization.pod_count}</span>
            ),
        },
        {
            field: 'organization.transferred_pod_count',
            headerName: 'Transferred Pods',
            width: 150,
            sortable: false,
            renderCell: (params: GridRenderCellParams<OrgSubscription, any, any>) => (
                <span>{params.row.organization.transferred_pod_count}</span>
            ),
        },
        {
            field: 'name',
            headerName: 'Subscription Name',
            width: 200,
            sortable: true,
            renderCell: (params: GridRenderCellParams<Subscription, string>) => {
                const { items } = params.row as Subscription;
                const itemCount = items ? items.length : 0;

                return (
                    <Tooltip
                        title={
                            <div>
                                {items.map((item) => (
                                    <div key={item.id}>
                                        {item.name} [{item.quantity}]
                                    </div>
                                ))}
                            </div>
                        }
                        arrow
                    >
                        <span style={{ cursor: 'pointer', fontSize: '0.8rem' }}>
                            {params.value} [{itemCount}]
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'stripe_id',
            headerName: 'Stripe Subscription ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<OrgSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/subscriptions/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_price',
            headerName: 'Stripe Price ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<OrgSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/prices/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: true,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            sortable: true,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 200,
            sortable: true,
            valueFormatter: (value: string | number | Date | null) =>
                value ? new Date(value).toLocaleDateString() : '',
        },
        {
            field: 'trial_ends_at',
            headerName: 'Trial End Date',
            width: 150,
            sortable: true,
            valueFormatter: (value: string | number | Date | null) =>
                value ? new Date(value).toLocaleDateString() : '',
        },
        {
            field: 'ends_at',
            headerName: 'End Date',
            width: 150,
            sortable: true,
            valueFormatter: (value: string | number | Date | null) =>
                value ? new Date(value).toLocaleDateString() : '',
        },
    ];

    const userColumns: GridColDef[] = [
        { field: 'user_name', headerName: 'User', width: 150, sortable: true },
        { field: 'name', headerName: 'Subscription Name', width: 200, sortable: true },
        {
            field: 'stripe_id',
            headerName: 'Stripe Subscription ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<UserSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/subscriptions/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_price',
            headerName: 'Stripe Price ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<UserSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/prices/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: true,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            sortable: true,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 200,
            sortable: true,
            valueFormatter: (value: string | number | Date | null) =>
                value ? new Date(value).toLocaleDateString() : '',
        },
        {
            field: 'trial_ends_at',
            headerName: 'Trial End Date',
            width: 150,
            sortable: true,
            valueFormatter: (value: string | number | Date | null) =>
                value ? new Date(value).toLocaleDateString() : '',
        },
        {
            field: 'ends_at',
            headerName: 'End Date',
            width: 150,
            sortable: true,
            valueFormatter: (value: string | number | Date | null) =>
                value ? new Date(value).toLocaleDateString() : '',
        },
    ];

    const handleOrgSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setOrgSearchParams({
            ...orgSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleUserSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setUserSearchParams({
            ...userSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleOrgSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...orgSearchParams,
                organization_uuid: orgSearchParams.organization_uuid || undefined,
                page: orgPaginationModel.page + 1,
                per_page: orgPaginationModel.pageSize,
            };
            await getOrgSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const handleUserSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...userSearchParams,
                user_name: userSearchParams.user_name || undefined,
                page: userPaginationModel.page + 1,
                per_page: userPaginationModel.pageSize,
            };
            await getUserSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrgSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };
        const response = await getOrgSubscriptions(paginatedParams);
        if (Array.isArray(response.data.data)) {
            setOrgSubscriptions(response.data.data);
            setOrgTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: subscriptions data is not an array');
        }
    };

    const getUserSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: userPaginationModel.page + 1,
            per_page: userPaginationModel.pageSize,
        };
        const response = await getUserSubscriptions(paginatedParams);
        if (Array.isArray(response.data.data)) {
            setUserSubscriptions(response.data.data);
            setUserTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: user subscriptions data is not an array');
        }
    };

    const handleOrgSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...orgSearchParams,
            sort: sortField as GetOrgSubscriptionParams['sort'],
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };

        await getOrgSubscriptionSearchResults(params);
    };

    const handleUserSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...userSearchParams,
            sort: sortField as GetUserSubscriptionParams['sort'],
            page: userPaginationModel.page + 1,
            per_page: userPaginationModel.pageSize,
        };

        await getUserSubscriptionSearchResults(params);
    };

    const handleOrgSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setOrgSearchParams({
            ...orgSearchParams,
            stripe_status: event.target.value as GetOrgSubscriptionParams['stripe_status'],
        });
    };

    const handleUserSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setUserSearchParams({
            ...userSearchParams,
            stripe_status: event.target.value as GetUserSubscriptionParams['stripe_status'],
        });
    };

    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <Snackbar
                open={!!snackMessage}
                autoHideDuration={6000}
                message={snackMessage}
                onClose={() => setSnackMessage('')}
            />
            <h2>Subscriptions</h2>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Subscription Item Change Requests</h4>
                <ResponsiveForm onSubmit={handleChangeRequestSearch} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                organizations.find(
                                    (org) =>
                                        org.uuid === changeRequestSearchParams.organization_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setChangeRequestSearchParams({
                                    ...changeRequestSearchParams,
                                    organization_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={organizations}
                            getOptionLabel={(option) => `${option.name} - ${option.uuid}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Organizations" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControl fullWidth size="small">
                            <InputLabel id="change-request-status-label">Status</InputLabel>
                            <Select
                                labelId="change-request-status-label"
                                id="change-request-status"
                                name="status"
                                value={changeRequestSearchParams.status}
                                onChange={handleChangeRequestSearchParameterChange}
                                label="Status"
                                variant="outlined"
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="requested">Requested</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Change Requests
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={changeRequests}
                    columns={changeRequestColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleChangeRequestSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    paginationMode="server"
                    disableColumnMenu={true}
                    pagination
                    rowCount={changeRequestTotalRows}
                    paginationModel={changeRequestPaginationModel}
                    onPaginationModelChange={setChangeRequestPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    sx={dataGridSx}
                />
            </Box>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter Organization Subscriptions</h4>
                <ResponsiveForm onSubmit={handleOrgSubscriptionSearch} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                organizations.find(
                                    (org) => org.uuid === orgSearchParams.organization_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setOrgSearchParams({
                                    ...orgSearchParams,
                                    organization_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={organizations}
                            getOptionLabel={(option) => `${option.name} - ${option.uuid}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Organizations" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="name"
                            label="Subscription Name"
                            value={orgSearchParams.name}
                            onChange={handleOrgSearchParameterChange}
                            placeholder="Enter Subscription Name"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControl fullWidth>
                            <InputLabel id="org-status-label">Status</InputLabel>
                            <Select
                                labelId="org-status-label"
                                size="small"
                                value={orgSearchParams.stripe_status}
                                onChange={handleOrgSelectStatusChange}
                                label="Status"
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="past_due">Past Due</MenuItem>
                                <MenuItem value="canceled">Canceled</MenuItem>
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Subscriptions
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={orgSubscriptions}
                    columns={orgColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleOrgSubscriptionSort(model)}
                    getRowId={(row) => row.id}
                    style={{ height: '624px', marginBottom: '50px' }}
                    paginationMode="server"
                    disableColumnMenu={true}
                    pagination
                    rowCount={orgTotalRows}
                    paginationModel={orgPaginationModel}
                    onPaginationModelChange={setOrgPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    sx={dataGridSx}
                />
            </Box>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter User Subscriptions</h4>
                <ResponsiveForm onSubmit={handleUserSubscriptionSearch} noValidate>
                    <ResponsiveFormItem>
                        <TextField
                            label="User Name"
                            name="user_name"
                            value={userSearchParams.user_name}
                            onChange={handleUserSearchParameterChange}
                            placeholder="Enter User Name"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            label="Subscription Name"
                            name="name"
                            value={userSearchParams.name}
                            onChange={handleUserSearchParameterChange}
                            placeholder="Enter Subscription Name"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControl fullWidth>
                            <InputLabel id="user-status-label">Status</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="user-status-label"
                                size="small"
                                value={userSearchParams.stripe_status}
                                onChange={handleUserSelectStatusChange}
                                label="Status"
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="past_due">Past Due</MenuItem>
                                <MenuItem value="canceled">Canceled</MenuItem>
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Subscriptions
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={userSubscriptions}
                    columns={userColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleUserSubscriptionSort(model)}
                    getRowId={(row) => row.id}
                    style={{ marginBottom: '50px' }}
                    paginationMode="server"
                    pagination
                    rowCount={userTotalRows}
                    paginationModel={userPaginationModel}
                    disableColumnMenu={true}
                    onPaginationModelChange={setUserPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    sx={dataGridSx}
                />
            </Box>
        </Box>
    );
};

export default SubscriptionList;
