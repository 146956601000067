import { CalendarTodayOutlined, PeopleAlt, QueryStats, Security } from '@mui/icons-material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { Navigation } from 'utils';
import { UseAdminStatus } from '../../contexts/AdminContext';

export type NavigationTabConfigProps = {
    value: Navigation;
    label: string;
    icon?: any;
    path: string;
    secondaryNavigationTabs?: NavigationTabConfigProps[];
    badgeContent?: number;
    title?: string;
};

const useNavigationTabConfig = (): NavigationTabConfigProps[] => {
    const { isAdmin, pendingOrders, pendingMarketplaceResellRequests } = UseAdminStatus();

    const baseConfig: NavigationTabConfigProps[] = [
        {
            value: Navigation.LEARN,
            label: 'Learn',
            icon: MenuBookOutlinedIcon,
            path: '/class',
            secondaryNavigationTabs: [
                {
                    value: Navigation.LEARN_DASHBOARD,
                    label: 'Dashboard',
                    path: '/class',
                },
                {
                    value: Navigation.LEARN_ASSESSMENTS,
                    label: 'Assessments',
                    path: '/class/assessments',
                },
                {
                    value: Navigation.LEARN_SUBMISSIONS,
                    label: 'Results',
                    path: '/class/submissions',
                },
            ],
        },
        {
            value: Navigation.TRAIN,
            label: 'Train',
            icon: DirectionsRunIcon,
            path: '/train',
            secondaryNavigationTabs: [
                {
                    value: Navigation.TRAIN_DASHBOARD,
                    label: 'Dashboard',
                    path: '/train',
                },
                {
                    value: Navigation.TRAIN_MOVEMENTS,
                    label: 'Movements',
                    path: '/train/movements',
                },
                {
                    value: Navigation.TRAIN_SESSIONS,
                    label: 'Sessions',
                    path: '/train/sessions',
                },
            ],
        },
        {
            value: Navigation.COMMUNITY,
            label: 'Community',
            icon: PeopleAlt,
            path: '/community/groups',
            secondaryNavigationTabs: [
                {
                    value: Navigation.COMMUNITY_GROUPS_TEAMS,
                    label: 'Groups & Teams',
                    path: '/community/groups',
                },
                {
                    value: Navigation.COMMUNITY_INDIVIDUALS,
                    label: 'Individuals',
                    path: '/community/individuals',
                },
                {
                    value: Navigation.COMMUNITY_REPORTS,
                    label: 'Reports',
                    path: '/community/reports',
                },
                {
                    value: Navigation.COMMUNITY_DASHBOARDS,
                    label: 'Dashboards',
                    path: '/community/dashboards',
                },
            ],
        },
        {
            value: Navigation.PLAN,
            label: 'Plan',
            icon: CalendarTodayOutlined,
            path: '/plan/timeline',
            secondaryNavigationTabs: [
                {
                    value: Navigation.PLAN_DASHBOARD,
                    label: 'Athlete Overview',
                    path: '/plan/dashboard',
                },

                /*
                    value: Navigation.PLAN_REPORTS,
                    label: 'Reports',
                    path: '/plan/reports',
                */
                {
                    label: 'Timeline',
                    value: Navigation.PLAN_TIMELINE,
                    path: '/plan/timeline',
                },
                {
                    value: Navigation.PLAN_CALENDAR,
                    label: 'Calendar',
                    path: '/plan/calendar',
                },
            ],
        },
        {
            value: Navigation.EXPLORE,
            label: 'Explore',
            icon: QueryStats,
            path: '/explore',
            secondaryNavigationTabs: [],
        },
    ];

    const adminTab: NavigationTabConfigProps = {
        value: Navigation.ADMIN,
        label: 'Admin',
        icon: Security,
        path: '/admin',
        secondaryNavigationTabs: [],
        badgeContent: pendingOrders || pendingMarketplaceResellRequests,
        title: `${pendingOrders} pending order${
            pendingOrders !== 1 ? 's' : ''
        } and ${pendingMarketplaceResellRequests} marketplace resell request${
            pendingMarketplaceResellRequests !== 1 ? 's' : ''
        }`,
    };

    return isAdmin ? [adminTab, ...baseConfig] : baseConfig;
};

{
    /*
        value: Navigation.SOCIAL,
        label: 'Social',
        icon: ForumOutlined,
        path: '/social/feed',
        secondaryNavigationTabs: [
            {
                value: Navigation.SOCIAL_POSTS,
                label: 'Feed',
                path: '/social/feed',
            },
            {
                value: Navigation.SOCIAL_FEEDS,
                label: 'My Posts',
                path: '/social/my-posts',
            },
        ],
    */
}

export default useNavigationTabConfig;
