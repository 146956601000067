import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { startNewFormSubmission, startNewFormSubmissionForSelf } from 'api';
import { Can, ChooseOrganizationDialog, FormSharingDrawer, SearchIndividual } from 'components';
import { DateTime } from 'luxon';
import React, { FC, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { Form, Organization, SharedUser, useToggle } from 'utils';
import { AssessmentListCard } from '../components';
import { duplicateForm } from '../learn.api';

type AssessmentListProps = {
    hasSearchValue?: boolean;
    assessments: Form[];
};

const AssessmentList: FC<React.PropsWithChildren<AssessmentListProps>> = ({
    hasSearchValue,
    assessments,
}) => {
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const [shareDrawerOpen, toggleShareDrawerOpen] = useToggle();
    const [duplicatingAssessment, setDuplicatingAssessment] = useState<Form | null>(null);
    const [, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [buttonAnchor, setButtonAnchor] = useState<null | HTMLElement>(null);
    const [activeMenu, setActiveMenu] = useState<null | string>(null);
    const [creatingActivity, setCreatingActivity] = useState(false);
    const [selectedFormId, setSelectedFormId] = useState<string>('');

    const handleShareClick = (uuid: string) => {
        toggleShareDrawerOpen();
        setActiveMenu(uuid);
        setSelectedFormId(uuid);
    };

    const handleShareDrawerClosed = () => {
        toggleShareDrawerOpen();
        setActiveMenu(null);
    };

    const onIndividualClicked = (individual: string, form: Form) => {
        push(`/class/assessments/${form.uuid}/assign/${individual}`);
    };
    const onBeginClicked = (individualId: string, form: Form) => {
        startNewSubmission(individualId, form);
    };

    const onBegin = (form: Form) => {
        startNewSubmissionForSelf(form);
    };

    const startNewSubmissionForSelf = async (form: Form): Promise<void> => {
        setCreatingActivity(true);
        try {
            const response: any = await startNewFormSubmissionForSelf(form.uuid, {
                date: DateTime.now(),
            });
            push(ROUTES.CompleteAssessment.path.replace(':id', response.data.uuid));
        } catch (err: any) {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: `We couldn't start this assessment. Make sure the admin has added you to this assessment and that you have permissions to begin.`,
                }),
            );
        }
    };
    const startNewSubmission = async (individualId: string, form: Form): Promise<void> => {
        setCreatingActivity(true);
        try {
            const response: any = await startNewFormSubmission(
                form.uuid,
                DateTime.now(),
                individualId,
            );
            push(ROUTES.CompleteAssessment.path.replace(':id', response.data.uuid));
        } catch (err: any) {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: `We had trouble submitting this assessment.`,
                }),
            );
        }
    };

    const handleButtonGroup = (event: MouseEvent<HTMLButtonElement>, uuid: string) => {
        event.stopPropagation();
        event.preventDefault();
        setButtonAnchor(event.currentTarget);
        setActiveMenu(uuid);
    };

    const handleCloseMenu = (event: any): void => {
        event.stopPropagation();
        event.preventDefault();
        setButtonAnchor(null);
        setActiveMenu(null);
    };

    return (
        <Box sx={(theme) => ({ marginBottom: theme.spacing(7.5) })}>
            {Boolean(duplicatingAssessment) && (
                <ChooseOrganizationDialog
                    open={true}
                    onOrganizationSelected={(selectedOrganization: Organization) => {
                        if (duplicatingAssessment) {
                            duplicateForm(duplicatingAssessment.uuid, {
                                organization: selectedOrganization.uuid,
                            })
                                .then(() => {})
                                .catch(() => {})
                                .finally(() => {
                                    setDuplicatingAssessment(null);
                                });
                        }
                    }}
                    onCancel={() => {
                        setDuplicatingAssessment(null);
                    }}
                    buttonText="Duplicate Assessment"
                />
            )}
            <Box
                sx={{ gap: 11 }}
                display="flex"
                flexDirection="row"
                justifyContent={'center'}
                flexWrap="wrap"
                pb={6}
            >
                {!assessments.length ? (
                    <Box mt={4} mx="auto">
                        <Typography align="center" color="textSecondary">
                            {hasSearchValue
                                ? 'No results found in this category'
                                : 'No assessment found in this category yet'}
                        </Typography>
                    </Box>
                ) : (
                    assessments.map((assessment) => (
                        <AssessmentListCard
                            key={assessment.uuid}
                            hasActiveMenuItem={Boolean(buttonAnchor && activeMenu)}
                            assessment={assessment}
                            beginButton={
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => onBegin(assessment)}
                                >
                                    Begin
                                </Button>
                            }
                            buttonGroup={
                                <>
                                    <Can I="form:view" this={assessment}>
                                        <>
                                            <IconButton
                                                sx={{ height: 12, padding: 0, width: 20 }}
                                                aria-label="assessment options"
                                                aria-haspopup="true"
                                                onClick={(e) =>
                                                    handleButtonGroup(e, assessment.uuid)
                                                }
                                                size="large"
                                            >
                                                <MoreHorizIcon />
                                            </IconButton>
                                            <Menu
                                                keepMounted
                                                anchorEl={buttonAnchor}
                                                sx={{
                                                    padding: '7px 10px',
                                                    zIndex: 3,
                                                    '& .MuiMenuItem-root': {
                                                        fontSize: 12,
                                                        lineHeight: '20px',
                                                        minHeight: 24,
                                                        padding: '0 10px',
                                                    },
                                                }}
                                                open={
                                                    Boolean(buttonAnchor) &&
                                                    activeMenu === assessment.uuid
                                                }
                                                onClose={handleCloseMenu}
                                                PaperProps={{
                                                    style: {
                                                        zIndex: Number.MAX_SAFE_INTEGER,
                                                    },
                                                }}
                                            >
                                                <Can I="form:update" this={assessment}>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            push(
                                                                ROUTES.CreateAssessment.path.replace(
                                                                    ':id',
                                                                    assessment.uuid,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                </Can>
                                                <Can I={'form:duplicate'} this={assessment}>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            setDuplicatingAssessment(assessment);
                                                        }}
                                                    >
                                                        Duplicate
                                                    </MenuItem>
                                                </Can>
                                                <Can I="form:share" this={assessment}>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            handleShareClick(assessment.uuid);
                                                        }}
                                                    >
                                                        Share
                                                    </MenuItem>
                                                </Can>
                                                <Can I="form:view" this={assessment}>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            push(
                                                                ROUTES.ViewAssessment.path.replace(
                                                                    ':id',
                                                                    assessment.uuid,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        View
                                                    </MenuItem>
                                                </Can>
                                                <Can I="form:view" this={assessment}>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            push(
                                                                ROUTES.AssessmentSubmissionPreview.path.replace(
                                                                    ':id',
                                                                    assessment.uuid,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        Preview
                                                    </MenuItem>
                                                </Can>
                                                <Can I="form:assign" this={assessment}>
                                                    <MenuItem>
                                                        <SearchIndividual
                                                            buttonText="Assign On Calendar"
                                                            buttonClass={{
                                                                color: 'inherit',
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                padding: 0,
                                                                textTransform: 'capitalize',
                                                                fontWeight: 'inherit',
                                                                fontSize: 12,
                                                                width: '100%',
                                                            }}
                                                            showLoadingOverlay={creatingActivity}
                                                            onIndividualSelected={(individual) =>
                                                                onIndividualClicked(
                                                                    individual[0].uuid,
                                                                    assessment,
                                                                )
                                                            }
                                                            organization={assessment.organization}
                                                        />
                                                    </MenuItem>
                                                </Can>
                                                <Can I="form:assign" this={assessment}>
                                                    <MenuItem>
                                                        <SearchIndividual
                                                            buttonText="Begin For Athlete"
                                                            buttonClass={{
                                                                color: 'inherit',
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                padding: 0,
                                                                textTransform: 'capitalize',
                                                                fontWeight: 'inherit',
                                                                fontSize: 12,
                                                                width: '100%',
                                                            }}
                                                            showLoadingOverlay={creatingActivity}
                                                            onIndividualSelected={(individual) =>
                                                                onBeginClicked(
                                                                    individual[0].uuid,
                                                                    assessment,
                                                                )
                                                            }
                                                            organization={assessment.organization}
                                                        />
                                                    </MenuItem>
                                                </Can>
                                            </Menu>
                                        </>
                                    </Can>
                                </>
                            }
                        />
                    ))
                )}
                <FormSharingDrawer
                    open={shareDrawerOpen}
                    onClose={handleShareDrawerClosed}
                    formId={selectedFormId}
                    onUsersLoaded={(users: Array<SharedUser>) => setSharedUsers(users)}
                />
            </Box>
        </Box>
    );
};

export { AssessmentList };
