import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    debounce,
    Grid,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { ChooseMediaDialog, confirmViaDialog } from 'components';
import EditAssessmentDialog from 'components/Dialogs/EditAssessmentDialog';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    addSectionToForm,
    copySectionToForm,
    formSelector,
    removeSectionFromForm,
    saveForm,
    updateFormDetails,
} from 'redux/reducers/form';
import { useAppDispatch } from 'redux/store';
import { ASSESSMENT_FILTER_OPTIONS, Section } from 'utils';
import { Media } from '../../../app.types';

import AssessmentDescriptionEditor from './AssessmentDescriptionEditor';
import CoverPhotoButton from './CoverPhotoButton';
import QuestionSectionCard from './QuestionSectionCard';
import ScrollToViewWrapper from './ScrollToViewWrapper';

const AssessmentForm = () => {
    const dispatch = useAppDispatch();
    const [saving, setSaving] = useState<boolean>(false);
    const { form, lastSaveAt, lastUpdatedAt, updating } = useSelector(formSelector);
    const [showAssessmentMediaUpload, setShowAssessmentMediaUpload] = useState<boolean>(false);

    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    const { organizations } = useContext(OrganizationsContext);

    let timeOut = useRef<any>(null);

    const moveSection = useCallback(
        (fromIndex: number, toIndex: number) => {
            if (!form) return;

            const newSections = [...form.sections];
            const [movedSection] = newSections.splice(fromIndex, 1);
            newSections.splice(toIndex, 0, movedSection);

            dispatch(
                updateFormDetails({
                    formUuid: form.uuid,
                    updatePayload: {
                        sections: newSections.map((section, index) => ({
                            ...section,
                            order: index,
                        })),
                    },
                }),
            );

            // Scroll to the moved section's new location after a short delay
            setTimeout(() => {
                const sectionElement = document.getElementById(`section-${movedSection.uuid}`);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 0);
        },
        [form, dispatch],
    );

    useEffect(() => {
        (function periodicallySaveForm() {
            clearTimeout(timeOut.current);

            if (new Date(lastUpdatedAt) > new Date(lastSaveAt) && !saving) {
                setSaving(true);
                dispatch(saveForm()).then(() => {
                    timeOut.current = setTimeout(periodicallySaveForm, 10 * 1000);
                    setSaving(false);
                });
            } else {
                timeOut.current = setTimeout(periodicallySaveForm, 10 * 1000);
            }
        })();

        return () => {
            clearTimeout(timeOut.current);
        };
    }, [form?.uuid, dispatch, lastUpdatedAt, lastSaveAt, saving]);

    const [assessmentUpdates, setAssessmentUpdates] = useState<
        Partial<Record<'title' | 'type' | 'organization', string>>
    >({});

    const updateAssessment = (attribute: string, value: string): void => {
        setAssessmentUpdates((prevState) => ({
            ...prevState,
            [attribute]: value,
        }));
    };

    const onSaveUpdates = () => {
        if (!form) return;
        const { title, type, organization } = assessmentUpdates;

        dispatch(
            updateFormDetails({
                formUuid: form.uuid,
                updatePayload: {
                    title: title !== undefined ? title : form.title,
                    organization:
                        organization !== undefined
                            ? organizations.find((o) => o.name === organization) ||
                              form.organization
                            : form.organization,
                    type: type !== undefined ? type : form.type,
                },
            }),
        );
    };

    const onFormAssetUploaded = (media: Media) => {
        dispatch(
            updateFormDetails({
                formUuid: form?.uuid as string,
                updatePayload: {
                    media_uuid: media.uuid,
                },
                imageUrl: media.url,
            }),
        );
    };

    const uploadCoverMedia = useCallback(() => setShowAssessmentMediaUpload(true), []);

    const [editModal, setEditModal] = useState<boolean>(false);
    const openEditModal = useCallback(() => {
        setEditModal(true);
    }, []);

    const closeEditModal = useCallback(() => {
        setEditModal(false);
    }, []);

    // For indexing list of questions to auto-scrolling to
    // during addition, copy, and/or deletion of sections
    const formSectionsLength = (form && form.sections?.length - 1) || 0;
    const [scrollToSectionIdx, setScrollToSectionIdx] = useState(-1);

    const onAddSection = (formUuid: string) => {
        dispatch(addSectionToForm({ formUuid }));
        setScrollToSectionIdx(formSectionsLength + 1);
    };
    const onCopySection = (section: Section) => {
        dispatch(copySectionToForm({ section }));
        setScrollToSectionIdx(formSectionsLength + 1);
    };

    const onDeleteSection = async ({
        formUuid,
        section,
    }: {
        formUuid: string;
        section: Section;
    }) => {
        if (
            await confirmViaDialog({
                confirmation: {
                    title: 'Are you sure?',
                    message: `This will delete section '${section.name}' and all its questions.`,
                },
            })
        ) {
            dispatch(
                removeSectionFromForm({
                    formUuid,
                    removedSectionUuid: section.uuid,
                }),
            );
            setScrollToSectionIdx(formSectionsLength - 1);
        }
    };

    return (
        <>
            {form ? (
                <Container maxWidth="md" sx={{ marginTop: 24 }}>
                    {/* Cover Photo */}

                    {breakpoint && (
                        <Box display="flex" flexDirection="column">
                            <CoverPhotoButton
                                coverUrl={form.image_urls['350']}
                                onClick={uploadCoverMedia}
                            />

                            <Box
                                my={12}
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        boxShadow: 'none',
                                        padding: 0,
                                    },
                                }}
                            >
                                <Grid container>
                                    {/* Title */}
                                    <Grid item xs={11}>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color: theme.palette.primary.dark,
                                                fontSize: 24,
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                [theme.breakpoints.up('md')]: {
                                                    fontSize: 34,
                                                },
                                            }}
                                        >
                                            {form?.title == 'Untitled Assessment'
                                                ? ''
                                                : form?.title || ''}
                                        </Typography>
                                    </Grid>

                                    {/* Editor Button */}
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{
                                            alignItems: 'flex-end',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Tooltip title="Edit assessment">
                                            <IconButton
                                                aria-label="edit"
                                                sx={{
                                                    marginTop: 0,
                                                    padding: 5,
                                                    [theme.breakpoints.up('md')]: {
                                                        // center the icon-button relative to the title
                                                        margin: '0 8px 12px 0',
                                                    },
                                                }}
                                                onClick={openEditModal}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    {/* Organization */}
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                border: '1px solid rgba(0, 62, 157, 0.5)',
                                                borderRadius: 4,
                                                color: theme.palette.primary.dark,
                                                backgroundColor: theme.palette.primary.contrastText,
                                                marginTop: 10,
                                                padding: '4px 10px',
                                                minHeight: 52,
                                                fontSize: 16,
                                                fontWeight: 400,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {form.organization.name}
                                        </Box>
                                    </Grid>

                                    {/* Assessment Type */}
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                border: '1px solid rgba(0, 62, 157, 0.5)',
                                                borderRadius: 4,
                                                color: theme.palette.primary.dark,
                                                backgroundColor: theme.palette.primary.contrastText,
                                                marginTop: 10,
                                                padding: '4px 10px',
                                                minHeight: 52,
                                                fontSize: 16,
                                                fontWeight: 400,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {ASSESSMENT_FILTER_OPTIONS.find((filterOption) => {
                                                return filterOption.value === form?.type;
                                            })?.name ?? 'Other'}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )}
                    {!breakpoint && (
                        <Grid container>
                            <Grid item xs={5}>
                                <CoverPhotoButton
                                    coverUrl={form.image_urls['350']}
                                    onClick={uploadCoverMedia}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Box
                                    my={12}
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            boxShadow: 'none',
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <Grid container>
                                        {/* Title */}
                                        <Grid item xs={11}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: theme.palette.primary.dark,
                                                    fontSize: 24,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    [theme.breakpoints.up('md')]: {
                                                        fontSize: 34,
                                                    },
                                                }}
                                            >
                                                {form?.title == 'Untitled Assessment'
                                                    ? ''
                                                    : form?.title || ''}
                                            </Typography>
                                        </Grid>

                                        {/* Editor Button */}
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                alignItems: 'flex-end',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Tooltip title="Edit assessment">
                                                <IconButton
                                                    aria-label="edit"
                                                    sx={{
                                                        marginTop: 0,
                                                        padding: 5,
                                                        [theme.breakpoints.up('md')]: {
                                                            // center the icon-button relative to the title
                                                            margin: '0 8px 12px 0',
                                                        },
                                                    }}
                                                    onClick={openEditModal}
                                                    size="large"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>

                                        {/* Organization */}
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    border: '1px solid rgba(0, 62, 157, 0.5)',
                                                    borderRadius: 4,
                                                    color: theme.palette.primary.dark,
                                                    backgroundColor:
                                                        theme.palette.primary.contrastText,
                                                    marginTop: 10,
                                                    padding: '4px 10px',
                                                    minHeight: 52,
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {form.organization.name}
                                            </Box>
                                        </Grid>

                                        {/* Assessment Type */}
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    border: '1px solid rgba(0, 62, 157, 0.5)',
                                                    borderRadius: 4,
                                                    color: theme.palette.primary.dark,
                                                    backgroundColor:
                                                        theme.palette.primary.contrastText,
                                                    marginTop: 10,
                                                    padding: '4px 10px',
                                                    minHeight: 52,
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {ASSESSMENT_FILTER_OPTIONS.find((filterOption) => {
                                                    return filterOption.value === form?.type;
                                                })?.name ?? 'Other'}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container>
                        {/* Description */}

                        <Grid item xs={12}>
                            <Box my={8}>
                                <Typography variant="caption">Description</Typography>
                                <AssessmentDescriptionEditor
                                    label="Description"
                                    placeholder="Enter description here"
                                    defaultValue={form?.description || ''}
                                    editorOverrides={{
                                        toolbar: {
                                            options: ['inline', 'emoji', 'list', 'link'],
                                            inline: {
                                                options: ['bold', 'italic', 'underline'],
                                            },
                                            emoji: {
                                                emojis: [
                                                    '✅',
                                                    '❌',
                                                    '🦑',
                                                    '👀',
                                                    '⏲️',
                                                    '🧠',
                                                    '💓',
                                                    '🔥',
                                                    '💣',
                                                    '💯',
                                                ],
                                            },
                                            list: {
                                                options: ['unordered', 'ordered'],
                                            },
                                            link: {
                                                options: ['link'],
                                                defaultTargetOption: '_blank',
                                            },
                                        },
                                    }}
                                    onChange={debounce((newDescription: string) => {
                                        dispatch(
                                            updateFormDetails({
                                                formUuid: form.uuid,
                                                updatePayload: {
                                                    description: newDescription,
                                                },
                                            }),
                                        );
                                    }, 2000)}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Assessment Attributes Editor */}

                    <Box id="sections-container">
                        {/* Question Sections */}
                        {form?.sections.map((section, idx) => (
                            <Box
                                key={`form-section-${section.uuid}`}
                                className="assessment-section-card"
                            >
                                <ScrollToViewWrapper shouldScrollTo={scrollToSectionIdx === idx}>
                                    <QuestionSectionCard
                                        section={section}
                                        disableDelete={idx == 0}
                                        sectionIndex={idx}
                                        formUuid={form.uuid}
                                        addSection={onAddSection}
                                        copySection={onCopySection}
                                        deleteSection={onDeleteSection}
                                        organization={form?.organization?.uuid ?? ''}
                                        onMoveUp={() => moveSection(idx, idx - 1)}
                                        onMoveDown={() => moveSection(idx, idx + 1)}
                                        canMoveUp={idx > 0}
                                        canMoveDown={idx < form.sections.length - 1}
                                    />
                                </ScrollToViewWrapper>
                            </Box>
                        ))}
                        {form.sections.length == 0 && (
                            <Typography variant="body2" align="center" style={{ marginBottom: 16 }}>
                                No question added.
                            </Typography>
                        )}
                    </Box>
                    <Box textAlign="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ marginBottom: 24 }}
                            onClick={() => onAddSection(form.uuid)}
                        >
                            Add Section
                        </Button>
                    </Box>

                    {/* Upload Cover Photo Modal */}
                    <ChooseMediaDialog
                        open={showAssessmentMediaUpload}
                        fullScreen={false}
                        onClose={() => setShowAssessmentMediaUpload(false)}
                        onSelected={(media) => {
                            setShowAssessmentMediaUpload(false);
                            onFormAssetUploaded(media);
                        }}
                        organization={form.organization.uuid}
                    />

                    {/* Edit Modal */}
                    {organizations.length && (
                        <EditAssessmentDialog
                            open={editModal}
                            loading={false}
                            updating={updating}
                            assessment={form}
                            organizations={organizations}
                            onChange={updateAssessment}
                            onClose={closeEditModal}
                            onSave={onSaveUpdates}
                        />
                    )}
                </Container>
            ) : (
                <Box pt={20} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default AssessmentForm;
