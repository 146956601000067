import { Filter } from '@cubejs-client/core';
import { OpenInNew } from '@mui/icons-material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridOverlay,
    GridRenderCellParams,
    GridRowParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { cubejsApi } from '../../../app.functions';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { useNonInitialEffect } from '../../../hooks';
import DimensionFilterBuilder from '../components/DimensionFilterBuilder';
import { PersonalRecordForSprintsFilter } from '../components/PersonalRecordForSprintsFilter';
import { SprintCategoryFilter } from '../components/SprintCategoryFilter';
import { getArrayOfStringsFromCubeFilter } from '../home.func';
import { userDataGetter, userDataMapper } from './Dashboard';

const distanceProfile = {
    'FactSprintsRanked.f0': false,
    'FactSprintsRanked.v0': false,
    'FactSprintsRanked.pMax': false,
    'FactSprintsRanked.drf': false,
    'FactSprintsRanked.rfMax': false,
};

const forceVelocityProfile = {
    'FactSprintsRanked.maxV0To40': false,
    'FactSprintsRanked.max5m0To10': false,
    'FactSprintsRanked.max5m0To20': false,
    'FactSprintsRanked.max5m0To25': false,
    'FactSprintsRanked.max5m0To30': false,
    'FactSprintsRanked.max5m0To35': false,
    'FactSprintsRanked.max5m0To40': false,
    'FactSprintsRanked.max5m20To30': false,
    'FactSprintsRanked.max5m30To40': false,
    'FactSprintsRanked.max5m40To50': false,
    'FactSprintsRanked.max5m40ToInf': false,
    'FactSprintsRanked.maxAvgV0To10': false,
    'FactSprintsRanked.maxAvgV0To20': false,
    'FactSprintsRanked.maxAvgV0To30': false,
    'FactSprintsRanked.maxAvgV0To40': false,
};

const simpleSpeedProfile = {
    'FactSprintsRanked.f0': false,
    'FactSprintsRanked.v0': false,
    'FactSprintsRanked.pMax': false,
    'FactSprintsRanked.drf': false,
    'FactSprintsRanked.rfMax': false,
    'FactSprintsRanked.maxV0To40': false,
    'FactSprintsRanked.max5m0To10': false,
    'FactSprintsRanked.max5m0To20': false,
    'FactSprintsRanked.max5m0To25': false,
    'FactSprintsRanked.max5m0To30': false,
    'FactSprintsRanked.max5m0To35': false,
    'FactSprintsRanked.max5m0To40': false,
    'FactSprintsRanked.max5m20To30': false,
    'FactSprintsRanked.max5m30To40': false,
    'FactSprintsRanked.max5m40To50': false,
    'FactSprintsRanked.max5m40ToInf': false,
    'FactSprintsRanked.maxAvgV0To10': false,
    'FactSprintsRanked.maxAvgV0To20': false,
    'FactSprintsRanked.maxAvgV0To30': false,
    'FactSprintsRanked.maxAvgV0To40': false,
};

const Dashboard = () => {
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime>>([null, null]);
    const [individualFilters, setIndividualFilters] = useState<{ [key: string]: any }>({});
    const [groupFilters] = useState<{ [key: string]: any }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [count, setCount] = useState<number>(0);
    const { organizations } = useContext(OrganizationsContext);

    const savedQueryOptions = localStorage.getItem('sprintDashboardQueryOptions');

    const initialQueryOptions = savedQueryOptions
        ? JSON.parse(savedQueryOptions)
        : {
              limit: 50,
              page: 1,
              sort: {
                  'DimDate.fullDate': 'desc',
              },
          };

    const getRowActions = useCallback((params: GridRowParams) => {
        let actions: React.ReactElement<GridActionsCellItemProps>[] = [];
        actions.push(
            <GridActionsCellItem
                key={'view-button'}
                icon={<OpenInNew />}
                onClick={(event) => {
                    window.open(
                        `/community/individuals/${params.row['DimIndividual.uuid']}/performance/${params.row['DimUpload.uuid']}/sprints/${params.row['DimSprint.uuid']}`,
                        '_blank',
                    );
                    event.stopPropagation();
                }}
                label={'View Sprint'}
            />,
        );
        return actions;
    }, []);

    //
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'DimIndividual.name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                sortable: true,
            },
            {
                field: 'DimOrganization.uuid',
                headerName: 'Organization',
                width: 100,
                filterable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <Avatar
                        src={organizations.find((o) => o.uuid === params.value)?.logo_url ?? ''}
                        sx={{ width: 40, height: 40 }}
                    />
                ),
            },
            {
                field: 'FactSprintsRanked.load',
                headerName: 'Load (kg)',
                filterable: false,
                sortable: true,
            },
            {
                field: 'DimDate.fullDate',
                headerName: 'Date',
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <>{moment(params.value).format('MM/DD/YYYY')}</>
                ),
            },
            {
                field: 'DimSprint.timestamp',
                headerName: 'Time',
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <>
                        {DateTime.fromMillis(+(params.value ?? 0)).toLocaleString(
                            DateTime.TIME_SIMPLE,
                        )}
                    </>
                ),
            },
            {
                field: 'FactSprintsRanked.vMaxMph',
                headerName: 'MaxV',
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {(params.value ?? 0).toFixed(2)}{' '}
                        {params.value === params.row['FactSprintsRanked.vMaxPersonalRecordMph'] && (
                            <ArrowUpwardIcon />
                        )}
                    </>
                ),
            },
            {
                field: 'FactSprintsRanked.duration',
                headerName: 'Duration',
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.maxV0To40',
                headerName: 'MaxV 40 Yd',
                filterable: false,
                sortable: true,
                minWidth: 135,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m0To10',
                headerName: 'Max5mV 0-10yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m0To20',
                headerName: 'Max5mV 0-20yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m0To25',
                headerName: 'Max5mV 0-25yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m0To30',
                headerName: 'Max5mV 0-30yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m0To35',
                headerName: 'Max5mV 0-35yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m0To40',
                headerName: 'Max5mV 0-40yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m20To30',
                headerName: 'Max5mV 20-30yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m30To40',
                headerName: 'Max5mV 30-40yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m40To50',
                headerName: 'Max5mV 40-50yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.max5m40ToInf',
                headerName: 'Max5mV 40+yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.maxAvgV0To10',
                headerName: 'AvgV 0-10yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.maxAvgV0To20',
                headerName: 'AvgV 0-20yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.maxAvgV0To30',
                headerName: 'AvgV 0-30yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.maxAvgV0To40',
                headerName: 'AvgV 0-40yd',
                minWidth: 150,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{(params.value ?? 0).toFixed(2)}</>
                ),
            },
            {
                field: 'FactSprintsRanked.pMax',
                headerName: 'Pmax',
                filterable: false,
                sortable: true,
            },
            {
                field: 'FactSprintsRanked.f0',
                headerName: 'Fo',
                filterable: false,
                sortable: true,
            },
            {
                field: 'FactSprintsRanked.drf',
                headerName: 'DRF',
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0) * 100).toFixed(2)}%`}</>
                ),
            },
            {
                field: 'FactSprintsRanked.rfMax',
                headerName: 'RF Max',
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0) * 100).toFixed(2)}%`}</>
                ),
            },

            {
                field: 'DimTag.name',
                headerName: 'Category',
                filterable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <>{params.value !== 'No tag' ? params.value : '-'}</>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'View Sprint',
                getActions: (params) => getRowActions(params),
            },
        ],
        [organizations, getRowActions],
    );

    const [queryOptions, setQueryOptions] = useState<{
        sort?: { [key: string]: 'asc' | 'desc' };
        limit: number;
        page: number;
        filters?: {
            individual?: Filter;
            group?: Filter;
            tag?: Filter;
            load?: Filter;
            organization?: Filter;
            before?: Filter;
            after?: Filter;
            user?: Filter;
        };
        segments?: string[];
    }>(initialQueryOptions);

    useEffect(() => {
        localStorage.setItem('sprintDashboardQueryOptions', JSON.stringify(queryOptions));
    }, [queryOptions]);

    const [debouncedQueryOptions] = useDebounce(queryOptions, 700);

    const handleGroupsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    group:
                        value.length > 0
                            ? {
                                  member: 'DimMember.groupUuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
            setIndividualFilters((filters) => ({
                ...filters,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            }));
        },
        [queryOptions],
    );

    const getInitialStateFromGroupQueryOptions = (
        queryOptions: any,
    ): { [key: string]: boolean } => {
        // Extract the relevant properties from queryOptions
        const { filters } = queryOptions;

        // Extract the 'group' filter from filters
        const groupFilter = filters?.group;

        // Create the initialState object
        const initialState = {
            groupFilter: groupFilter || null, // Use null as a default value if 'group' filter is not present
        };

        return initialState;
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQueryOptions((o) => ({
            ...o,
            sort: sortModel.reduce((result: { [key: string]: any }, item: GridSortItem) => {
                result[item.field] = item.sort;
                return result;
            }, {}),
        }));
    }, []);

    const handleIndividualsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                page: 1,
                filters: {
                    ...queryOptions.filters,
                    individual:
                        value.length > 0
                            ? {
                                  member: 'DimIndividual.uuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    const getInitialStateFromIndividualQueryOptions = (
        queryOptions: any,
    ): { [key: string]: boolean } => {
        // Extract the relevant properties from queryOptions
        const { filters } = queryOptions;

        // Extract the 'individual' filter from filters
        const individualFilter = filters?.individual;

        // Create the initialState object
        const initialState = {
            individualFilter: individualFilter || null, // Use null as a default value if 'individual' filter is not present
        };

        return initialState;
    };

    useNonInitialEffect(() => {
        let before: Filter;
        let after: Filter;
        if (dateRangeValue[0]) {
            after = {
                member: 'DimDate.fullDate',
                operator: 'afterDate',
                values: [dateRangeValue[0]?.toISODate()],
            } as Filter;
        }
        if (dateRangeValue[1]) {
            before = {
                member: 'DimDate.fullDate',
                operator: 'beforeDate',
                values: [dateRangeValue[1]?.toISODate()],
            } as Filter;
        }
        setQueryOptions((options) => {
            return {
                ...options,
                page: 1,
                filters: {
                    before: before,
                    after: after,
                },
            };
        });
    }, [dateRangeValue]);

    useEffect(() => {
        setLoading(true);
        const filtersToLoad = Object.values(debouncedQueryOptions.filters ?? {}).filter((f) => !!f);
        filtersToLoad.push({
            member: 'DimUpload.isDeleted',
            operator: 'equals',
            values: ['0'],
        });
        cubejsApi
            .load({
                dimensions: [
                    'FactSprintsRanked.factSprintKey',
                    'DimIndividual.name',
                    'DimIndividual.uuid',
                    'DimIndividual.managingUserUuid',
                    'DimUpload.uuid',
                    'DimSprint.uuid',
                    'DimOrganization.name',
                    'DimOrganization.uuid',
                    'DimDate.fullDate',
                    'FactSprintsRanked.load',
                    'DimSprint.timestamp',
                    'DimSprint.csvPath',
                    'FactSprintsRanked.vMaxMph',
                    'FactSprintsRanked.vMaxPersonalRecordMph',
                    'FactSprintsRanked.v0Mph',
                    'FactSprintsRanked.pMax',
                    'FactSprintsRanked.f0',
                    'FactSprintsRanked.drf',
                    'FactSprintsRanked.rfMax',
                    'FactSprintsRanked.duration',
                    'FactSprintsRanked.maxV0To40',
                    'FactSprintsRanked.max5m0To10',
                    'FactSprintsRanked.max5m0To20',
                    'FactSprintsRanked.max5m0To25',
                    'FactSprintsRanked.max5m0To30',
                    'FactSprintsRanked.max5m0To35',
                    'FactSprintsRanked.max5m0To40',
                    'FactSprintsRanked.max5m20To30',
                    'FactSprintsRanked.max5m30To40',
                    'FactSprintsRanked.max5m40To50',
                    'FactSprintsRanked.max5m40ToInf',
                    'FactSprintsRanked.maxAvgV0To10',
                    'FactSprintsRanked.maxAvgV0To20',
                    'FactSprintsRanked.maxAvgV0To30',
                    'FactSprintsRanked.maxAvgV0To40',
                    'DimTag.name',
                ],
                limit: debouncedQueryOptions.limit,
                offset: (debouncedQueryOptions.page - 1) * debouncedQueryOptions.limit,
                filters: filtersToLoad,
                segments: debouncedQueryOptions.segments,
                order: debouncedQueryOptions.sort,
            })
            .then((resultSet: any) => {
                setRows(resultSet.tablePivot());
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => setLoading(false));

        cubejsApi
            .load({
                measures: ['FactSprintsRanked.Count'],
                filters: Object.values(debouncedQueryOptions.filters ?? {}).filter((f) => !!f),
                segments: debouncedQueryOptions.segments,
            })
            .then((resultSet: any) => {
                setCount(resultSet.rawData()[0]['FactSprintsRanked.Count']);
            });
    }, [debouncedQueryOptions]);

    // add settings icon and filter to show/hide columns

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [layoutFormat, setLayoutFormat] = React.useState<
        'simple_speed_profile' | 'force_velocity_profile' | 'distance_profile'
    >('simple_speed_profile');
    const [visibilityObject, setVisibilityObject] = React.useState<{ [key: string]: boolean }>(
        simpleSpeedProfile,
    );

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newLayoutFormat: 'distance_profile' | 'force_velocity_profile' | 'simple_speed_profile',
    ) => {
        setLayoutFormat(newLayoutFormat);

        if (newLayoutFormat === 'distance_profile') {
            setVisibilityObject(distanceProfile);
        }
        if (newLayoutFormat === 'simple_speed_profile') {
            setVisibilityObject(simpleSpeedProfile);
        } else if (newLayoutFormat === 'force_velocity_profile') {
            setVisibilityObject(forceVelocityProfile);
        }
        setTimeout(handleClose, 150);
    };

    const handleAthletesChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    user:
                        value.length > 0
                            ? {
                                  member: 'DimSupportTeam.athleteUuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    const [resetKey, setResetKey] = useState<number>(0);

    const resetFilters = () => {
        const defaultOptions = {
            limit: 50,
            page: 1,
            sort: {
                'DimDate.fullDate': 'desc' as const, // Ensure date is sorted in descending order
            },
        };

        localStorage.setItem('sprintDashboardQueryOptions', JSON.stringify(defaultOptions));
        setQueryOptions(defaultOptions);
        setDateRangeValue([null, null]);
        setIndividualFilters({});
        setResetKey((prevKey) => prevKey + 1); // Increment the resetKey
    };
    const castIconStyle: React.CSSProperties = {
        animation: 'constantScale 3s infinite', // Apply the animation
        cursor: 'pointer',
    };
    const CustomNoRowsOverlay = () => {
        return (
            <GridOverlay>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>
                        Sprint Data is automatically processed from raw GPS Data and 1080 Motion
                        Running Data.
                    </Typography>
                    <Typography>
                        This allows you in-depth analysis of a single sprint, Change of Direction,
                        and all sprints in a single session.
                    </Typography>
                    <Typography>
                        Sprint Data will be presented here once it is imported from the raw gps file
                        or automatically processed thru the 1080 Motion API.
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography>
                            Learn more about this by clicking on the Learn Center at the top right
                            of your screen:{' '}
                        </Typography>
                        <div style={castIconStyle}>
                            <CastForEducationIcon sx={{ marginRight: 8, marginLeft: 9 }} />
                        </div>
                    </div>
                </div>
            </GridOverlay>
        );
    };

    return (
        <>
            <Paper
                sx={{
                    width: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Stack direction="row" spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                    <DimensionFilterBuilder
                        id={'filter-list-groups'}
                        resourcePath="groups"
                        buttonText="Group(s)"
                        onChange={handleGroupsFilterChanged}
                        otherFilters={groupFilters}
                        key={`group-filter-${resetKey}`}
                        initialState={getInitialStateFromGroupQueryOptions(queryOptions)}
                    />
                    <DimensionFilterBuilder
                        id={'filter-list-individuals'}
                        resourcePath="individuals"
                        buttonText="Athletes"
                        onChange={handleIndividualsFilterChanged}
                        otherFilters={individualFilters}
                        key={`individual-filter-${resetKey}`}
                        initialState={getInitialStateFromIndividualQueryOptions(queryOptions)}
                    />
                    <DimensionFilterBuilder
                        initialState={{}}
                        onChange={debounce((value) => {
                            handleAthletesChanged(value);
                        }, 1000)}
                        dataGetter={(page, limit, payload) => userDataGetter(page, limit, payload)}
                        dataMapper={userDataMapper}
                        buttonText={'Users'}
                        id={'search-users'}
                        key={`users-filter-${resetKey}`}
                    />
                    <CheckboxFilterList
                        buttonText={'Organizations'}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                filters: {
                                    ...f.filters,
                                    organization:
                                        v.length > 0
                                            ? {
                                                  member: 'DimOrganization.uuid',
                                                  operator: 'equals',
                                                  values: v,
                                              }
                                            : undefined,
                                },
                            }))
                        }
                        options={organizations.map((o) => ({
                            label: o.name,
                            value: o.uuid,
                            avatar_url: o.logo_url,
                        }))}
                        value={getArrayOfStringsFromCubeFilter(queryOptions.filters?.organization)}
                        key={`organization-filter-${resetKey}`}
                    />
                    <PersonalRecordForSprintsFilter
                        value={queryOptions.segments ?? []}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                segments: v,
                            }))
                        }
                        key={`pr-filter-${resetKey}`}
                    />
                    <SprintCategoryFilter
                        value={getArrayOfStringsFromCubeFilter(queryOptions.filters?.tag)}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                filters: {
                                    ...f.filters,
                                    tag:
                                        v.length > 0
                                            ? {
                                                  member: 'DimTag.tagId',
                                                  operator: 'equals',
                                                  values: v,
                                              }
                                            : undefined,
                                },
                            }))
                        }
                        key={`sprint_category-filter-${resetKey}`}
                    />
                    <CheckboxFilterList
                        buttonText={'Load'}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                filters: {
                                    ...f.filters,
                                    load:
                                        v.length > 0
                                            ? {
                                                  member: 'FactSprintsRanked.load',
                                                  operator: 'equals',
                                                  values: v as string[],
                                              }
                                            : undefined,
                                },
                            }))
                        }
                        options={[
                            { label: '-7kg', value: '-7' },
                            { label: '-5kg', value: '-5' },
                            { label: '-3kg', value: '-3' },
                            { label: '0kg', value: '0' },
                            { label: '1kg', value: '1' },
                            { label: '3kg', value: '3' },
                            { label: '5kg', value: '5' },
                            { label: '7kg', value: '7' },
                            { label: '10kg', value: '10' },
                            { label: '15kg', value: '15' },
                            { label: '30kg', value: '30' },
                        ]}
                        value={getArrayOfStringsFromCubeFilter(queryOptions.filters?.load)}
                        key={`load-filter-${resetKey}`}
                    />
                    <CustomDateRangePicker
                        value={dateRangeValue}
                        onChange={(newValue) => {
                            setDateRangeValue(newValue);
                        }}
                    />
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <Button
                            onClick={resetFilters}
                            variant={
                                Object.values(queryOptions?.filters ?? {}).some((f) => !!f) ||
                                (queryOptions?.segments ?? []).length > 0
                                    ? 'contained'
                                    : 'outlined'
                            }
                            color="primary"
                        >
                            {Object.values(queryOptions?.filters ?? {}).some((f) => !!f) ||
                            (queryOptions?.segments ?? []).length > 0
                                ? 'Reset Filters'
                                : 'No Filters'}
                        </Button>
                    </Box>
                    <IconButton
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        size={'small'}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <SettingsIcon fontSize={'large'} color={'primary'} />
                    </IconButton>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <ToggleButtonGroup
                                color="primary"
                                value={layoutFormat}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="simple_speed_profile">
                                    Simple Speed Metrics
                                </ToggleButton>
                                <ToggleButton value="distance_profile">
                                    Distance Metrics
                                </ToggleButton>
                                <ToggleButton value="force_velocity_profile">
                                    Force Velocity Metrics
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </MenuItem>
                    </Menu>
                </Stack>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        width: '100%',
                    }}
                >
                    <DataGridPremium
                        sx={{
                            backgroundColor: '#ffffff',
                            boxShadow: { lg: 3 },
                            borderRadius: { lg: 3 },
                            padding: { lg: 2 },
                            '.MuiDataGrid-root .MuiDataGrid-overlay': {
                                pointerEvents: 'none',
                            },
                            '.MuiDataGrid-virtualScrollerContent': {
                                height: '100%!important',
                            },
                        }}
                        autoHeight={false}
                        density="compact"
                        pageSizeOptions={[100, 200, 300]}
                        rows={rows}
                        columnVisibilityModel={visibilityObject}
                        columns={columns}
                        initialState={{
                            pinnedColumns: { left: ['DimIndividual.name'] },
                            sorting: { sortModel: [{ field: 'DimDate.fullDate', sort: 'desc' }] },
                        }}
                        getRowId={(row) => {
                            return row['FactSprintsRanked.factSprintKey'];
                        }}
                        sortingMode="server"
                        sortModel={Object.entries(queryOptions.sort ?? {}).map(([field, sort]) => ({
                            field,
                            sort,
                        }))}
                        onSortModelChange={handleSortModelChange}
                        loading={loading}
                        disableRowSelectionOnClick
                        slots={{
                            footer: CustomPagination,
                            loadingOverlay: CustomLoadingOverlay,
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                            footer: {
                                hasNext: true,
                                hasPrevious: queryOptions.page > 1,
                                onNext: () => setQueryOptions((o) => ({ ...o, page: o.page + 1 })),
                                onPrevious: () =>
                                    setQueryOptions((o) => ({ ...o, page: o.page - 1 })),
                                page: queryOptions.page,
                                total: count,
                            },
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
export default Dashboard;
