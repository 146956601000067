import { Filter } from '@cubejs-client/core';
import { ArrowDownward, OpenInNew } from '@mui/icons-material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import SettingsIcon from '@mui/icons-material/Settings';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Paper, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridOverlay,
    GridRenderCellParams,
    GridRowParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { cubejsApi, millisecondsToDurationString } from 'app.functions';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getAllAthletesPaginated } from '../../../api/app.api';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import { AbilityContext } from '../../../components/Functional/Can';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { useNonInitialEffect } from '../../../hooks';
import { UserLimited } from '../../../utils';
import { GenericSubject } from '../../../utils/ability';
import DimensionFilterBuilder from '../components/DimensionFilterBuilder';
import { PersonalRecordFilter } from '../components/PersonalRecordFilter';
import { getArrayOfStringsFromCubeFilter } from '../home.func';

interface CustomNoDataOverlayProps {
    resetFilters: () => void;
    queryOptions: {
        filters?: {
            individual?: Filter;
            user?: Filter;
            group?: Filter;
            movement?: Filter;
            organization?: Filter;
            before?: Filter;
            after?: Filter;
        };
        segments?: string[];
    };
}

export const userDataGetter = (page: number, limit: number, payload: Record<string, any>) =>
    getAllAthletesPaginated({
        page,
        limit,
        ...payload,
    });

export const userDataMapper = (response: any) => {
    return {
        dimensions: response.data.map((u: UserLimited) => ({
            title: `${u.name}`,
            image_url: `${u.profile_photo}`,
            uuid: u.uuid,
        })),
        page: response.meta.current_page,
        hasMore: Boolean(response.links.next),
    };
};

const castIconStyle: React.CSSProperties = {
    animation: 'constantScale 3s infinite', // Apply the animation
    cursor: 'pointer',
};
const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography>
                    Train data comes from logging movement in a session under from Train module.
                </Typography>
                <Typography>This is different than gps data.</Typography>
                <Typography>
                    Data will populate here once you or your athletes log training data.
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography>
                        Learn more about this by clicking on the Learn Center at the top right of
                        your screen:{' '}
                    </Typography>
                    <div style={castIconStyle}>
                        <CastForEducationIcon sx={{ marginRight: 8, marginLeft: 9 }} />
                    </div>
                </div>
            </div>
        </GridOverlay>
    );
};
const CustomNoDataOverlay: React.FC<React.PropsWithChildren<CustomNoDataOverlayProps>> = ({
    resetFilters,
    queryOptions,
}) => {
    return (
        <GridOverlay style={{ pointerEvents: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box component="span" sx={{ pointerEvents: 'auto' }}>
                    <Typography>
                        No Data found. Please reset filters to allow data to be presented.
                    </Typography>
                </Box>
                <Box
                    component="span"
                    sx={{ marginRight: 1, marginTop: 4, spacing: 1, pointerEvents: 'auto' }}
                >
                    <Button
                        style={{ position: 'relative', zIndex: 2, marginTop: 4 }}
                        onClick={resetFilters}
                        variant={
                            Object.values(queryOptions?.filters ?? {}).some((f) => !!f) ||
                            (queryOptions?.segments ?? []).length > 0
                                ? 'contained'
                                : 'outlined'
                        }
                        color="primary"
                    >
                        {Object.values(queryOptions?.filters ?? {}).some((f) => !!f) ||
                        (queryOptions?.segments ?? []).length > 0
                            ? 'Reset Filters'
                            : 'No Filters'}
                    </Button>
                </Box>
            </div>
        </GridOverlay>
    );
};

const Dashboard = () => {
    const [cubeRows, setCubeRows] = useState<any>([]);
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime>>([null, null]);
    const [individualFilters, setIndividualFilters] = useState<{ [key: string]: any }>({});
    const [groupFilters] = useState<{ [key: string]: any }>({});
    const [movementFilters] = useState<{ [key: string]: any }>({});
    let { push } = useHistory();
    const ability = useContext(AbilityContext);
    const savedQueryOptions = localStorage.getItem('queryOptions');
    const storedUnit = localStorage.getItem('distanceUnit');
    const [distanceUnit, setDistanceUnit] = useState<'yard' | 'inch'>(
        storedUnit === 'inch' || storedUnit === 'yard' ? storedUnit : 'yard',
    );
    const [anchorElSecond, setAnchorElSecond] = React.useState<null | HTMLElement>(null);
    const openSecond = Boolean(anchorElSecond);

    const handleClickSecond = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSecond(event.currentTarget);
    };
    const handleCloseSecond = () => {
        setAnchorElSecond(null);
    };

    const initialQueryOptions = savedQueryOptions
        ? JSON.parse(savedQueryOptions)
        : {
              sort: {
                  'DimDate.fullDate': 'desc',
              },
              limit: 100,
              page: 1,
              'filter[duration]': '1',
          };
    const [queryOptions, setQueryOptions] = useState<{
        sort?: { [key: string]: 'asc' | 'desc' };
        limit: number;
        page: number;
        filters?: {
            individual?: Filter;
            user?: Filter;
            group?: Filter;
            movement?: Filter;
            organization?: Filter;
            before?: Filter;
            after?: Filter;
        };
        segments?: string[];
    }>(initialQueryOptions);

    useEffect(() => {
        localStorage.setItem('queryOptions', JSON.stringify(queryOptions));
    }, [queryOptions]);

    const [isLoading, setIsLoading] = useState(false);
    const [debouncedQueryOptions] = useDebounce(queryOptions, 700);
    const { organizations } = useContext(OrganizationsContext);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQueryOptions((o) => ({
            ...o,
            sort: sortModel.reduce((result: { [key: string]: any }, item: GridSortItem) => {
                result[item.field] = item.sort;
                return result;
            }, {}),
        }));
    }, []);

    const handleAthletesChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    user:
                        value.length > 0
                            ? {
                                  member: 'DimSupportTeam.athleteUuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    const handleIndividualsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    individual:
                        value.length > 0
                            ? {
                                  member: 'DimIndividual.uuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    const handleGroupsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    group:
                        value.length > 0
                            ? {
                                  member: 'DimMember.groupUuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
            setIndividualFilters((filters) => ({
                ...filters,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            }));
        },
        [queryOptions],
    );

    const handleMovementsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    movement:
                        value.length > 0
                            ? {
                                  member: 'DimMovement.uuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    useNonInitialEffect(() => {
        let before: Filter;
        let after: Filter;
        if (dateRangeValue[0]) {
            after = {
                member: 'DimDate.fullDate',
                operator: 'afterDate',
                values: [dateRangeValue[0]?.toISODate()],
            } as Filter;
        }
        if (dateRangeValue[1]) {
            before = {
                member: 'DimDate.fullDate',
                operator: 'beforeDate',
                values: [dateRangeValue[1]?.toISODate()],
            } as Filter;
        }
        setQueryOptions((options) => {
            return {
                ...options,
                filters: {
                    ...options.filters,
                    before: before,
                    after: after,
                },
            };
        });
    }, [dateRangeValue]);

    useEffect(() => {
        setIsLoading(true);

        cubejsApi
            .load({
                dimensions: [
                    'DimIndividual.name',
                    'DimOrganization.uuid',
                    'DimIndividual.uuid',
                    'DimIndividual.managingUserUuid',
                    'DimMovement.name',
                    'DimDate.fullDate',
                    'DimActivity.uuid',
                    'FactLogsWithPersonalRecords.factLogKey',
                    'FactLogsWithPersonalRecords.timeDisplayFormat',
                    'FactLogsWithPersonalRecords.speed',
                    'FactLogsWithPersonalRecords.topSpeed',
                    'FactLogsWithPersonalRecords.power',
                    'FactLogsWithPersonalRecords.topPower',
                    'FactLogsWithPersonalRecords.timeMs',
                    'FactLogsWithPersonalRecords.lowestTime',
                    'FactLogsWithPersonalRecords.distance',
                    'FactLogsWithPersonalRecords.topDistance',
                    'FactLogsWithPersonalRecords.acceleration',
                    'FactLogsWithPersonalRecords.topLoad',
                    'FactLogsWithPersonalRecords.load',
                ],
                limit: debouncedQueryOptions.limit,
                offset: (debouncedQueryOptions.page - 1) * debouncedQueryOptions.limit,
                filters: Object.values(debouncedQueryOptions.filters ?? {}).filter((f) => !!f),
                segments: debouncedQueryOptions.segments,
                order: debouncedQueryOptions.sort,
            })
            .then((resultSet) => {
                setCubeRows(resultSet.tablePivot());
            })
            .catch(() => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [debouncedQueryOptions]);

    const getRowActions = useCallback(
        (params: GridRowParams) => {
            let actions: React.ReactElement<GridActionsCellItemProps>[] = [];
            const genericIndividual = {
                uuid: params.row['DimIndividual.uuid'],
                object: 'individual',
                organization_uuid: params.row['DimOrganization.uuid'],
                managing_user: params.row['DimIndividual.managingUserUuid']
                    ? { uuid: params.row['DimIndividual.managingUserUuid'] }
                    : null,
            } as GenericSubject;
            if (ability.can('individual:manage-logs', genericIndividual)) {
                actions.push(
                    <GridActionsCellItem
                        key={'view-button'}
                        icon={<OpenInNew />}
                        onClick={() => {
                            push(`/activities/${params.row['DimActivity.uuid']}`);
                        }}
                        label={'View Sprint'}
                    />,
                );
            }
            return actions;
        },
        [push, ability],
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'DimIndividual.name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                groupable: true,
            },
            {
                field: 'DimOrganization.uuid',
                headerName: 'Organization',
                width: 100,
                filterable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <Avatar
                        src={organizations.find((o) => o.uuid === params.value)?.logo_url ?? ''}
                        sx={{ width: 40, height: 40 }}
                    />
                ),
            },
            {
                field: 'DimMovement.name',
                headerName: 'Movement',
                filterable: false,
                minWidth: 200,
                groupable: false,
            },
            {
                field: 'DimDate.fullDate',
                headerName: 'Date',
                filterable: false,
                groupable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <>{moment(params.value).format('MM/DD/YYYY')}</>
                ),
            },
            {
                field: 'FactLogsWithPersonalRecords.speed',
                headerName: 'Speed (mph)',
                filterable: false,
                groupable: false,
                minWidth: 100,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {params.value ? (
                            <>
                                {(params.value ?? 0).toFixed(2)}{' '}
                                {params.value ===
                                    params.row['FactLogsWithPersonalRecords.topSpeed'] && (
                                    <ArrowUpwardIcon />
                                )}
                            </>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                field: 'FactLogsWithPersonalRecords.distance',
                headerName: distanceUnit === 'yard' ? 'Distance (yd)' : 'Distance (in)',
                filterable: false,
                groupable: false,
                minWidth: 125,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {params.value ? (
                            <>
                                {(distanceUnit === 'yard'
                                    ? params.value ?? 0
                                    : (params.value ?? 0) * 36
                                ).toFixed(2)}{' '}
                                {params.value ===
                                    params.row['FactLogsWithPersonalRecords.topDistance'] && (
                                    <ArrowUpwardIcon />
                                )}
                            </>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                field: 'FactLogsWithPersonalRecords.timeMs',
                headerName: 'Time',
                filterable: false,
                groupable: false,
                minWidth: 125,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {params.value
                            ? millisecondsToDurationString(
                                  params.value,
                                  params.row['FactLogsWithPersonalRecords.timeDisplayFormat'],
                              )
                            : '-'}{' '}
                        {params.value &&
                            params.value ===
                                params.row['FactLogsWithPersonalRecords.lowestTime'] * 1000 && (
                                <ArrowDownward />
                            )}
                    </>
                ),
            },
            {
                field: 'FactLogsWithPersonalRecords.power',
                headerName: 'Power (w)',
                filterable: false,
                groupable: false,
                minWidth: 125,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {params.value ? (
                            <>
                                {(params.value ?? 0).toFixed(2)}{' '}
                                {params.value ===
                                    params.row['FactLogsWithPersonalRecords.topPower'] && (
                                    <ArrowUpwardIcon />
                                )}
                            </>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                field: 'FactLogsWithPersonalRecords.load',
                headerName: 'Load (lb)',
                filterable: false,
                groupable: false,
                minWidth: 125,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {params.value ? (
                            <>
                                {(params.value ?? 0).toFixed(2)}{' '}
                                {params.value ===
                                    params.row['FactLogsWithPersonalRecords.topLoad'] && (
                                    <ArrowUpwardIcon />
                                )}
                            </>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                field: 'FactLogsWithPersonalRecords.reps',
                headerName: 'Reps',
                filterable: false,
                groupable: false,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>
                        {params.value ? (
                            <>
                                {(params.value ?? 0).toFixed(2)}{' '}
                                {params.value ===
                                    params.row['FactLogsWithPersonalRecords.topReps'] && (
                                    <ArrowUpwardIcon />
                                )}
                            </>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                field: 'actions',
                headerName: 'View Log',
                type: 'actions',
                getActions: (params: GridRowParams) => getRowActions(params),
            },
        ],
        [distanceUnit, organizations, getRowActions],
    );

    // add settings icon and filter to show/hide columns

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [layoutFormat, setLayoutFormat] = React.useState<'all' | 'track' | 'field'>('all');
    const [visibilityObject, setVisibilityObject] = React.useState({});

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newLayoutFormat: 'all' | 'track' | 'field',
    ) => {
        setLayoutFormat(newLayoutFormat);
        if (newLayoutFormat === 'all') {
            setVisibilityObject({});
        }
        if (newLayoutFormat === 'track') {
            setVisibilityObject({
                'FactLogsWithPersonalRecords.reps': false,
                'FactLogsWithPersonalRecords.load': false,
                'FactLogsWithPersonalRecords.power': false,
                'FactLogsWithPersonalRecords.distance': false,
                'FactLogsWithPersonalRecords.speed': false,
            });
        }
        if (newLayoutFormat === 'field') {
            setVisibilityObject({
                'FactLogsWithPersonalRecords.reps': false,
                'FactLogsWithPersonalRecords.load': false,
                'FactLogsWithPersonalRecords.power': false,
                'FactLogsWithPersonalRecords.timeMs': false,
                'FactLogsWithPersonalRecords.speed': false,
            });
        }
        setTimeout(handleClose, 250);
    };

    const [resetKey, setResetKey] = useState<number>(0);

    const resetFilters = () => {
        const defaultOptions = {
            limit: 100,
            page: 1,
            'filter[duration]': '1',
            sort: {
                'DimDate.fullDate': 'desc' as const, // Ensure date is sorted in descending order
            },
        };

        localStorage.setItem('queryOptions', JSON.stringify(defaultOptions));
        setQueryOptions(defaultOptions);
        setDateRangeValue([null, null]);
        setIndividualFilters({});
        setResetKey((prevKey) => prevKey + 1); // Increment the resetKey
    };
    useEffect(() => {
        localStorage.setItem('distanceUnit', distanceUnit);
    }, [distanceUnit]);

    return (
        <>
            <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ marginY: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <DimensionFilterBuilder
                            id={'filter-list-groups'}
                            resourcePath="groups"
                            buttonText="Group(s)"
                            onChange={handleGroupsFilterChanged}
                            otherFilters={groupFilters}
                            key={`group-filter-${resetKey}`}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <DimensionFilterBuilder
                            id={'filter-list-individuals'}
                            resourcePath="individuals"
                            buttonText="Athletes"
                            onChange={handleIndividualsFilterChanged}
                            otherFilters={individualFilters}
                            key={`individual-filter-${resetKey}`}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <Box sx={{ marginRight: 1, spacing: 1 }}>
                            <DimensionFilterBuilder
                                initialState={{}}
                                onChange={debounce((value) => {
                                    handleAthletesChanged(value);
                                }, 1000)}
                                dataGetter={(page, limit, payload) =>
                                    userDataGetter(page, limit, payload)
                                }
                                dataMapper={userDataMapper}
                                buttonText={'Users'}
                                id={'search-users'}
                                key={`users-filter-${resetKey}`}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <CheckboxFilterList
                            buttonText={'Organizations'}
                            onChange={(v) =>
                                setQueryOptions((f) => ({
                                    ...f,
                                    filters: {
                                        ...f.filters,
                                        organization:
                                            v.length > 0
                                                ? {
                                                      member: 'DimOrganization.uuid',
                                                      operator: 'equals',
                                                      values: v,
                                                  }
                                                : undefined,
                                    },
                                }))
                            }
                            options={organizations.map((o) => ({
                                label: o.name,
                                value: o.uuid,
                                avatar_url: o.logo_url,
                            }))}
                            value={getArrayOfStringsFromCubeFilter(
                                queryOptions.filters?.organization,
                            )}
                            key={`organization-filter-${resetKey}`}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <DimensionFilterBuilder
                            id={'filter-list-movements'}
                            resourcePath="movements"
                            buttonText="Movements"
                            onChange={handleMovementsFilterChanged}
                            otherFilters={movementFilters}
                            key={`movement-filter-${resetKey}`}
                        />
                    </Box>

                    <PersonalRecordFilter
                        value={queryOptions.segments ?? []}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                segments: v,
                            }))
                        }
                        key={`pr-filter-${resetKey}`}
                    />

                    <Box sx={{ padding: 2 }}>
                        <CustomDateRangePicker
                            value={dateRangeValue}
                            onChange={(newValue) => {
                                setDateRangeValue(newValue);
                            }}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <Button
                            onClick={resetFilters}
                            variant={
                                Object.values(queryOptions?.filters ?? {}).some((f) => !!f) ||
                                (queryOptions?.segments ?? []).length > 0
                                    ? 'contained'
                                    : 'outlined'
                            }
                            color="primary"
                        >
                            {Object.values(queryOptions?.filters ?? {}).some((f) => !!f) ||
                            (queryOptions?.segments ?? []).length > 0
                                ? 'Reset Filters'
                                : 'No Filters'}
                        </Button>
                    </Box>
                    <IconButton
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        size={'large'}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <SettingsIcon fontSize={'large'} color={'primary'} />
                    </IconButton>
                    <IconButton
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        size={'large'}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickSecond}
                        color={'primary'}
                    >
                        <SwitchAccessShortcutAddIcon fontSize={'large'} />
                    </IconButton>

                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <ToggleButtonGroup
                                color="primary"
                                value={layoutFormat}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="all">All</ToggleButton>
                                <ToggleButton value="track">Track</ToggleButton>
                                <ToggleButton value="field">Field</ToggleButton>
                            </ToggleButtonGroup>
                        </MenuItem>
                    </Menu>
                    <Menu
                        id="distance-conversion-menu"
                        aria-labelledby="distance-conversion-button"
                        anchorEl={anchorElSecond}
                        open={openSecond}
                        onClose={handleCloseSecond}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <ToggleButtonGroup
                                color="primary"
                                value={distanceUnit}
                                exclusive
                                onChange={(event, newUnit) => {
                                    if (newUnit !== null) {
                                        setDistanceUnit(newUnit);
                                    }
                                    // Close the menu after .25 seconds (250 milliseconds)
                                    setTimeout(handleCloseSecond, 250);
                                }}
                                aria-label="Platform"
                            >
                                <ToggleButton value="yard">Yards</ToggleButton>
                                <ToggleButton value="inch">Inches</ToggleButton>
                            </ToggleButtonGroup>
                        </MenuItem>
                    </Menu>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        '& .super-app.normal': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        '& .super-app.negative': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        '& .super-app.positive': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                    }}
                >
                    <DataGridPremium
                        sx={{
                            backgroundColor: '#ffffff',
                            boxShadow: { lg: 3 },
                            borderRadius: { lg: 3 },
                            padding: { lg: 2 },
                            '.MuiDataGrid-virtualScrollerContent': {
                                height: '100%!important',
                            },
                        }}
                        getRowId={(row) => row['FactLogsWithPersonalRecords.factLogKey']}
                        autoHeight={false}
                        density="compact"
                        rows={cubeRows}
                        columnVisibilityModel={visibilityObject}
                        columns={columns}
                        initialState={{
                            pinnedColumns: { left: ['dimIndividual.name'] },
                            sorting: { sortModel: [{ field: 'DimDate.fullDate', sort: 'desc' }] },
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        loading={isLoading}
                        disableRowSelectionOnClick
                        slots={{
                            footer: CustomPagination,
                            loadingOverlay: CustomLoadingOverlay,
                            noRowsOverlay:
                                (queryOptions?.segments?.length ?? 0) > 0
                                    ? () => (
                                          <CustomNoDataOverlay
                                              resetFilters={resetFilters}
                                              queryOptions={queryOptions}
                                          />
                                      ) // Pass props here
                                    : CustomNoRowsOverlay,
                        }}
                        slotProps={{
                            footer: {
                                hasNext: true,
                                hasPrevious: queryOptions.page > 1,
                                onNext: () => setQueryOptions((o) => ({ ...o, page: o.page + 1 })),
                                onPrevious: () =>
                                    setQueryOptions((o) => ({ ...o, page: o.page - 1 })),
                                page: queryOptions.page,
                                total: 0,
                            },
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
export default Dashboard;
