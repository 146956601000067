import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
    useReactFlow,
    ReactFlowProvider,
} from 'reactflow';
import PartnerViewNode from '../components/PartnerViewNode';
import InviteNewPartnerNode from '../components/InviteNewPartnerNode';
import { Box, Fab, Typography, List, ListItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    useNetworkingBinaryTree,
    useNetworkingOrganization,
    useNetworkingRanks,
} from '../state/NetworkingContext';
import { useHistory } from 'react-router-dom';
import FilterPanel from '../components/NodeFilter';
import { filterNodes, getLayoutedElements } from '../networking.functions';

const Flow = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [rankFilter, setRankFilter] = useState<string[]>([]);
    const [salesFilter, setSalesFilter] = useState<number | ''>('');
    const [subscriptionFilter, setSubscriptionFilter] = useState<string[]>([]);
    const [athleteFilter, setAthleteFilter] = useState<string[]>([]);
    const nodeTypes = useMemo(
        () => ({ partnerViewer: PartnerViewNode, inviteNewPartner: InviteNewPartnerNode }),
        [],
    );
    const binaryTree = useNetworkingBinaryTree();
    const organization = useNetworkingOrganization();
    const ranksData = useNetworkingRanks();
    const { push } = useHistory();
    const { setCenter } = useReactFlow();

    useEffect(() => {
        if (!binaryTree.loaded || !binaryTree.data || !organization?.data || !ranksData.loaded) {
            return;
        }
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            binaryTree.data,
            organization.data.uuid || '',
            true,
            ranksData.data || [],
        );
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [setNodes, setEdges, binaryTree, organization, ranksData]);

    const filteredNodes = useMemo(
        () =>
            filterNodes(
                nodes,
                nameFilter,
                rankFilter,
                salesFilter,
                subscriptionFilter,
                athleteFilter,
            ),
        [nodes, nameFilter, rankFilter, salesFilter, subscriptionFilter, athleteFilter],
    );

    const filteredEdges = useMemo(() => {
        const nodeIds = new Set(filteredNodes.map((node) => node.id));
        return edges.filter((edge) => nodeIds.has(edge.source) && nodeIds.has(edge.target));
    }, [filteredNodes, edges]);

    const alphabetizedNodes = useMemo(() => {
        return filteredNodes
            .filter((node) => node.data.label !== 'Create' && node.data.matchesFilter)
            .sort((a, b) => a.data.label.localeCompare(b.data.label))
            .map((node) => ({ id: node.id, label: node.data.label }));
    }, [filteredNodes]);

    const handleNodeClick = useCallback(
        (nodeId: string) => {
            const node = nodes.find((n) => n.id === nodeId);
            if (node) {
                setCenter(node.position.x + 200, node.position.y + 100, {
                    zoom: 1,
                    duration: 1000,
                });
            }
        },
        [nodes, setCenter],
    );

    return (
        <Box sx={{ height: '100%', display: 'flex' }}>
            {/* Filters Panel */}
            <Box
                sx={{
                    width: '15%',
                    p: 2,
                    borderRight: 1,
                    borderColor: 'divider',
                    backgroundColor: 'white',
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Filters
                </Typography>
                <FilterPanel
                    nameFilter={nameFilter}
                    setNameFilter={setNameFilter}
                    rankFilter={rankFilter}
                    setRankFilter={setRankFilter}
                    salesFilter={salesFilter}
                    setSalesFilter={setSalesFilter}
                    subscriptionFilter={subscriptionFilter}
                    setSubscriptionFilter={setSubscriptionFilter}
                    athleteFilter={athleteFilter}
                    setAthleteFilter={setAthleteFilter}
                />
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                    }}
                >
                    <Typography gutterBottom>Organizations ({alphabetizedNodes.length})</Typography>
                    {alphabetizedNodes.length ? (
                        <List
                            dense
                            disablePadding
                            sx={{
                                border: '1px solid lightgray',
                                padding: '4px',
                                borderRadius: '4px',
                                maxHeight: '180px',
                                overflowY: 'auto',
                            }}
                        >
                            {alphabetizedNodes.map((node) => (
                                <ListItem
                                    key={node.id}
                                    disableGutters
                                    onClick={() => handleNodeClick(node.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    <Typography variant="body2">{node.label}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        ''
                    )}
                </Box>
            </Box>

            {/* ReactFlow Canvas */}
            <Box sx={{ width: '85%', height: '100%', position: 'relative' }}>
                <Fab
                    sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1000 }}
                    color={'primary'}
                    variant="extended"
                    onClick={() => {
                        push(`/invite-organization/${organization?.data?.uuid}`);
                    }}
                >
                    <AddIcon />
                    Add Partner
                </Fab>
                <ReactFlow
                    nodes={filteredNodes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    edges={filteredEdges}
                    nodeTypes={nodeTypes}
                    minZoom={0.1}
                    fitView
                >
                    <Background />
                    <Controls />
                    <MiniMap />
                </ReactFlow>
            </Box>
        </Box>
    );
};

const BinaryTreeScene = () => {
    return (
        <ReactFlowProvider>
            <Flow />
        </ReactFlowProvider>
    );
};

export default BinaryTreeScene;
