import { ClickAwayListener, InputBase } from '@mui/material';
import { millisecondsToDurationString } from 'app.functions';
import { padEnd } from 'lodash';
import moment from 'moment';
import 'moment-duration-format';
import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { TimeDisplayFormat } from 'utils';
import MuiTextField from '@mui/material/TextField';

export interface SessionTimeInputProps {
    defaultValueInMilliseconds?: number;
    format: TimeDisplayFormat;
    onTimeChanged: (updatedValue: number) => void;
}

export const durationStringToMilliseconds = (
    duration: string,
    format: TimeDisplayFormat,
): number => {
    // Duration moment plugin uses 00:00:00.xxx to make sure it correctly parses the format
    switch (format) {
        case TimeDisplayFormat.Second:
            duration = `00:00:${duration}`;
            break;
        case TimeDisplayFormat.Meet:
            duration = `00:00:${duration}`;
            break;
        case TimeDisplayFormat.Run:
            duration = `00:${duration}`;
            break;
        case TimeDisplayFormat.Common:
            duration = `00:${duration}`;
            break;
        default:
            break;
    }

    return moment.duration(duration).asMilliseconds();
};

const SessionTimeInput = ({
    defaultValueInMilliseconds = 0,
    format = TimeDisplayFormat.Common,
    onTimeChanged,
}: SessionTimeInputProps) => {
    const getMask = (timeFormat: TimeDisplayFormat) => {
        return (timeFormat as string).replace(/([a-zA-Z ])/g, '9');
    };

    const getPlaceholder = (timeFormat: TimeDisplayFormat) => {
        return (timeFormat as string).replace(/([a-zA-Z ])/g, '0');
    };

    const [value, setValue] = useState<string>(
        millisecondsToDurationString(defaultValueInMilliseconds, format),
    );

    const evaluateTime = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        let newValue = e.target.value;
        if (newValue != value) {
            setValue(newValue);
            onTimeChanged(durationStringToMilliseconds(newValue.replace(/_/g, '0'), format));
        }
    };

    // When user clicks away, we want to replace the placeholder `_`s with 0s
    const beautifyValue = () => {
        if (!value) {
            return;
        }

        setValue(padEnd(value.replace(/_/g, '0')));
    };

    return (
        <>
            <div>
                <ClickAwayListener onClickAway={() => beautifyValue()}>
                    <ReactInputMask
                        mask={getMask(format)}
                        // There is a known issue of `maskChar` appearing as an invalid property.
                        // It is an issue with @types/react-input-mask, which is missing some properties in the types definitions
                        maskChar={'_'}
                        value={value as string}
                        onChange={evaluateTime}
                        onFocus={(e) => {
                            e.target.setSelectionRange(
                                e.target.value.length,
                                e.target.value.length,
                                'forward',
                            );
                            e.target.select();
                        }}
                    >
                        <MuiTextField
                            placeholder={getPlaceholder(format)}
                            inputProps={{ style: { textAlign: 'center' } }}
                            InputProps={{
                                inputComponent: InputBase as any,
                            }}
                        />
                    </ReactInputMask>
                </ClickAwayListener>
            </div>
        </>
    );
};

export default SessionTimeInput;
