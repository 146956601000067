import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from './organizations.types';

interface PaginatedApiResponse {
    data: Array<Organization>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

interface GetOrganizationsAndSubscriptionDataParams {
    organization_uuid?: string;
    sort?:
        | 'created_at'
        | '-created_at'
        | 'name'
        | '-name'
        | 'athlete_count'
        | '-athlete_count'
        | 'athlete_pod_subscriptions'
        | '-athlete_pod_subscriptions'
        | 'uploads_this_month'
        | '-uploads_this_month'
        | 'subscription_level'
        | '-subscription_level'
        | 'total_commissions'
        | '-total_commissions'
        | 'marketplace_commissions'
        | '-marketplace_commissions'
        | 'pib_bonus_commissions'
        | '-pib_bonus_commissions'
        | 'residual_commissions'
        | '-residual_commissions'
        | 'check_match_commissions'
        | '-check_match_commissions'
        | 'org_pod_subscriptions'
        | '-org_pod_subscriptions';
    page?: number;
    per_page?: number;
}

const getOrganizationsAndSubscriptionData = (
    params?: GetOrganizationsAndSubscriptionDataParams,
): ReturnTypeCollection => {
    console.log(params);
    const filterParams = {
        'filter[organization_uuid]': params?.organization_uuid,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/organizations/dashboard`, { params: filterParams });
};

export { getOrganizationsAndSubscriptionData };
export type { GetOrganizationsAndSubscriptionDataParams };
