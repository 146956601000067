import { Box } from '@mui/material';
import { LayoutWithSearch } from 'components';
import { LayoutWithSearchSkeleton } from 'components/Skeletons';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import {
    getAllAssessments,
    getAssessmentsSelector,
    loadAssessments,
} from 'redux/reducers/assessments';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { StringParam, useQueryParams } from 'use-query-params';
import { useToggle } from 'utils';
import { OrganizationFilterList } from '../../../components/Filters';
import { AssessmentList, AssessmentListSkeleton, CreateAssessmentModal } from '../components';

const AssessmentListDashboard: FC<React.PropsWithChildren<unknown>> = () => {
    const { push } = useHistory();

    /** Querystring getting & setting */
    const [query, setQuery] = useQueryParams({
        'filter[title]': StringParam,
        'filter[type]': StringParam,
        'filter[organization]': StringParam,
        cursor: StringParam,
    });
    const debounceLoadData = useCallback(debounce(setQuery, 400), []); // eslint-disable-line react-hooks/exhaustive-deps

    /** Redux data fetching/dispatching */
    const dispatch = useAppDispatch();
    const { isLoading, isLoaded, error } = useSelector((state: RootState) =>
        getAssessmentsSelector(state),
    );

    const assessments = useSelector(getAllAssessments);

    /** component state management (accordion toggle, searching, filtering) */

    const [searchValue, setSearchValue] = useState<string>(query['filter[title]'] as string);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isDialogOpen, toggleIsDialogOpen] = useToggle(false);

    /** methods for setting state + setting qs params */

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debounceLoadData({ 'filter[title]': event.target.value });
    };

    const createNewAssessment = (created: boolean, uuid?: string) => {
        toggleIsDialogOpen();
        if (created && uuid) {
            push(ROUTES.CreateAssessment.path.replace(':id', uuid));
        }
    };

    useEffect(() => {
        dispatch(loadAssessments({ query }));
    }, [dispatch, query]);

    useEffect(() => {
        if (error.message) {
            setShowErrorMessage(true);
        }
    }, [error.message]);

    // organization filters

    const { organizations } = useContext(OrganizationsContext);

    if (isLoading && !isLoaded) return <LayoutWithSearchSkeleton />;

    const onOrganizationSelected = (organization: string | false) => {
        if (organization === query['filter[organization]']) {
            setQuery({ ...query, 'filter[organization]': null });

            return;
        }

        if (organization) {
            setQuery(() => ({ ...query, 'filter[organization]': organization, page: 1 }));
        }
    };

    return (
        <LayoutWithSearch
            fabText={'Create New Learn Content'}
            isError={Boolean(error.message && showErrorMessage)}
            searchValue={searchValue}
            errorMessage={error.message}
            setSearchValue={onSearchChange}
            setOpen={setShowErrorMessage}
            onClickButton={toggleIsDialogOpen}
        >
            <Box
                sx={{
                    marginBottom: 16,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <OrganizationFilterList
                    organizations={organizations}
                    onClicked={(organization) => onOrganizationSelected(organization.uuid)}
                    selectedOrganization={query['filter[organization]'] ?? ''}
                />
            </Box>
            {isLoading ? (
                <AssessmentListSkeleton />
            ) : (
                <div style={{ width: '100%' }}>
                    <AssessmentList
                        assessments={assessments}
                        hasSearchValue={Boolean(searchValue?.length)}
                    />
                </div>
            )}
            <CreateAssessmentModal open={isDialogOpen} onClose={createNewAssessment} />
        </LayoutWithSearch>
    );
};

export { AssessmentListDashboard };
