import { hierarchy, HierarchyPointNode, tree } from 'd3-hierarchy';
import { FlowEdge, FlowNode, Rank, TreeNodes } from './networking.types';
import { graphlib, layout } from 'dagre';
import { Node } from 'reactflow';
import { RankNumber, rankMapping, athleteRanges } from './networking.constants';
import { NodeData } from './networking.types';

const g = tree<TreeNodes>();

const nodeWidth = 300;
const nodeHeight = 275;

export function addPlaceholderChildren(node: TreeNodes, organization: string) {
    if (node.is_placeholder) {
        return;
    }
    for (let i = 0; i < 2; i++) {
        if (node.children.length < 2) {
            node.children.push({
                name: `${node.partner}-placeholder-${i}`,
                partner: `Create`,
                partner_details: {
                    name: `Create`,
                },
                leadership_rank: 0,
                levels: {
                    athleteCount: 0,
                    organizationSubscriptionLevel: 0,
                    marketplaceSales: 0,
                },
                organization,
                rank: 0,
                is_placeholder: true,
                children: [],
            });
        }
    }
    node.children.forEach((n) => addPlaceholderChildren(n, organization));
    return node;
}

export const getLayoutedElements = (
    rootNode: TreeNodes,
    organization: string,
    addPlaceholders: boolean = true,
    ranks: Rank[] = [],
    orientation: 'vertical' | 'horizontal' = 'vertical',
): { nodes: FlowNode[]; edges: FlowEdge[] } => {
    if (addPlaceholders) {
        addPlaceholderChildren(rootNode, organization);
    }

    if (orientation === 'vertical') {
        // Use d3-hierarchy for vertical layout
        const d3Hierarchy = hierarchy(rootNode);
        const treeLayout = g.nodeSize([nodeWidth, nodeHeight]);
        const layout: HierarchyPointNode<TreeNodes> = treeLayout(d3Hierarchy);
        const descendants = layout.descendants();
        const flowNodes = descendants.map((node: HierarchyPointNode<any>) => ({
            ...node.data,
            id: node.data.name,
            data: {
                partnerId: node.data.name,
                label: node.data.partner_details.name,
                parent: node.parent ? node.parent.data.name : '',
                matchesFilter: true,
                rank: node.data.rank,
                leadership_rank: node.data.leadership_rank,
                levels: node.data.levels,
                context: {
                    organization: node.data.organization,
                    ranks: ranks,
                },
            },
            type: node.data.is_placeholder ? 'inviteNewPartner' : 'partnerViewer',
            position: { x: node.x, y: node.y },
            draggable: false,
        }));

        return {
            nodes: flowNodes,
            edges: createEdgesFromNodes(flowNodes),
        };
    } else {
        // Use Dagre for horizontal layout
        const graph = new graphlib.Graph();
        graph.setGraph({ rankdir: 'LR', ranksep: nodeHeight, nodesep: nodeWidth / 2 });

        const addNodeRecursively = (node: TreeNodes, parent: string | null = null) => {
            if (!node || !node.name) return;

            graph.setNode(node.name, { width: nodeWidth, height: nodeHeight });
            if (parent) {
                graph.setEdge(parent, node.name, { weight: 1 });
            }
            if (node.children && Array.isArray(node.children)) {
                node.children.forEach((child) => addNodeRecursively(child, node.name));
            }
        };

        addNodeRecursively(rootNode);

        try {
            layout(graph);
        } catch (error) {
            console.error('Error in Dagre layout:', error);
            return getLayoutedElements(rootNode, organization, false, ranks, 'vertical');
        }

        const flowNodes: FlowNode[] = [];
        graph.nodes().forEach((nodeName) => {
            const node = graph.node(nodeName);
            const originalNode = findNodeByName(rootNode, nodeName);
            if (originalNode && node) {
                flowNodes.push({
                    ...originalNode,
                    id: nodeName,
                    data: {
                        partnerId: originalNode.partner,
                        label: originalNode.partner_details.name,
                        parent: graph.predecessors(nodeName)?.[0] || '',
                        rank: originalNode.rank,
                        matchesFilter: true,
                        leadership_rank: originalNode.leadership_rank,
                        levels: originalNode.levels,
                        context: {
                            organization: organization,
                            ranks: ranks,
                        },
                    },
                    type: originalNode.is_placeholder ? 'inviteNewPartner' : 'partnerViewer',
                    position: { x: node.x, y: node.y },
                    draggable: false,
                });
            }
        });

        const edges: FlowEdge[] = graph.edges().map((e) => ({
            id: `e${e.v}-${e.w}`,
            source: e.v,
            target: e.w,
            type: 'smoothstep',
        }));

        return { nodes: flowNodes, edges };
    }
};

export const createEdgesFromNodes = (nodes: FlowNode[]): FlowEdge[] => {
    return nodes
        .filter((node) => Boolean(node.data.parent)) // Filter out nodes without a parent
        .map((node) => ({
            id: `e${node.data.parent}-${node.id}`, // Unique edge id
            source: node.data.parent, // Source node id (parent)
            target: node.id, // Target node id/ Edge type
            type: 'smoothstep', // Edge type
        }));
};

export const convertRankLevelToRank = (rankLevel: number, ranks: Rank[]): string => {
    return ranks.find((rank) => rank.level === rankLevel)?.name || 'Not Ranked';
};

export const convertOrganizationLevelToLevel = (organizationLevel: number): string => {
    switch (organizationLevel) {
        case 1:
            return 'Bronze';
        case 2:
            return 'Silver';
        case 3:
            return 'Gold';
        default:
            return '-';
    }
};

const findNodeByName = (node: TreeNodes, name: string): TreeNodes | null => {
    if (node.name === name) return node;
    if (node.children) {
        for (const child of node.children) {
            const found = findNodeByName(child, name);
            if (found) return found;
        }
    }
    return null;
};

export const isRankNumber = (rank: number): rank is RankNumber => {
    return rank >= 0 && rank <= 5;
};

export const filterNodes = (
    nodes: Node[],
    nameFilter: string,
    rankFilter: string[],
    salesFilter: number | '',
    subscriptionFilter: string[],
    athleteFilter: string[],
) => {
    return nodes.map((node) => {
        const data = node.data as NodeData;
        const nameMatch = data.label.toLowerCase().includes(nameFilter.toLowerCase());

        let rankMatch = rankFilter.length === 0;
        if (!rankMatch && isRankNumber(data.rank)) {
            rankMatch = rankFilter.includes(rankMapping[data.rank]);
        } else if (!rankMatch) {
            rankMatch = rankFilter.includes('Not Ranked');
        }

        const salesMatch = salesFilter === '' || data.levels.marketplaceSales >= salesFilter;

        const subscriptionLevel =
            ['Bronze', 'Silver', 'Gold'][data.levels.organizationSubscriptionLevel - 1] || '-';
        const subscriptionMatch =
            subscriptionFilter.length === 0 || subscriptionFilter.includes(subscriptionLevel);

        const athleteCount = data.levels.athleteCount;
        const athleteMatch =
            athleteFilter.length === 0 ||
            athleteFilter.some((range) => {
                const { min, max } = athleteRanges.find((r) => r.label === range) || {
                    min: 0,
                    max: Infinity,
                };
                return athleteCount >= min && athleteCount <= max;
            });

        let matchesFilter =
            nameMatch && rankMatch && salesMatch && subscriptionMatch && athleteMatch;

        return { ...node, data: { ...node.data, matchesFilter: matchesFilter } };
    });
};
