import QrCode2Icon from '@mui/icons-material/QrCode2';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import { getSession } from 'api';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Session } from 'utils';
import { SessionSharingDrawer } from '../../components';
import { AbilityContext } from '../../components/Functional/Can';
import SessionSummary from './SessionSummary';

const ViewSession = () => {
    const { push } = useHistory();
    let { id: sessionUuid } = useParams<{ id: string }>();

    const [session, setSession] = useState<Session | null>(null);
    const [sessionShareDrawer, setSessionShareDrawer] = useState<boolean>(false);
    const ability = useContext(AbilityContext);

    // Load the form from the database
    useEffect(() => {
        if (!session) {
            getSession(sessionUuid).then((response) => {
                let session = response.data as Session;
                // Making sure the session movements are shown as per their order in the backend
                session?.movements?.sort(
                    (m1, m2) => (m1?.pivot?.order || 0) - (m2?.pivot?.order || 0),
                );
                setSession(response.data as Session);
            });
        }
    }, [sessionUuid, session]);

    return (
        <FixedLayout
            title={session ? session.title : 'View Session'}
            titleActions={
                <React.Fragment>
                    {session &&
                        (ability.can('session:share', session) ||
                            ability.can('session:share-partial', session)) && (
                            <IconButton onClick={() => setSessionShareDrawer(true)} size="large">
                                <QrCode2Icon color="inherit" />
                                <ShareIcon color="inherit" />
                            </IconButton>
                        )}
                </React.Fragment>
            }
            content={
                session ? (
                    <React.Fragment>
                        {sessionShareDrawer && (
                            <SessionSharingDrawer
                                open={true}
                                onClose={() => setSessionShareDrawer(false)}
                                sessionUuid={session.uuid}
                                restrictsRolesToShare={!ability.can('session:share', session)}
                                onUsersLoaded={() => {}}
                            />
                        )}
                        <SessionSummary session={session as Session} />
                    </React.Fragment>
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )
            }
            footerActions={
                <>
                    {session && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    push(ROUTES.ListOfSessions.path);
                                }}
                            >
                                Return to session list
                            </Button>
                        </Box>
                    )}
                </>
            }
        ></FixedLayout>
    );
};

export default ViewSession;
