import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    TextField,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import {
    getOrganizationsAndSubscriptionData,
    GetOrganizationsAndSubscriptionDataParams,
} from '../organizations.api';
import { Organization } from '../organizations.types';
import { Organization as SimpleOrganization } from 'utils';
import { GridColDef } from '@mui/x-data-grid-premium';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import { debounce } from 'lodash';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(20% - 16px)', // Subtracting margin to maintain layout
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        // Changed from 'sm' to 'md'
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1), // Negative margin to counteract item margins
    [theme.breakpoints.down('md')]: {
        // Changed from 'sm' to 'md'
        flexDirection: 'column',
    },
}));

const OrganizationsList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [filterOrganizations, setFilterOrganizations] = useState<SimpleOrganization[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [searchParams, setSearchParams] = useState<GetOrganizationsAndSubscriptionDataParams>({
        organization_uuid: '',
        sort: '-created_at',
        page: 1,
        per_page: 10,
    });

    const [totalOrganizations, setTotalOrganizations] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(false);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setIsLoading(true);
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        });
        getOrganizationsAndSubscriptionData({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        })
            .then((response) => {
                setOrganizations(response.data.data);
                setTotalOrganizations(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchFilterOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setFilterOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchFilterOrganizations('', 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getOrganizationsAndSubscriptionData({ organization_uuid: '', sort: '-created_at' })
            .then((response) => {
                setOrganizations(response.data.data);
                setTotalOrganizations(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    const columns: GridColDef<Organization, any, any>[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            sortable: true,
            type: 'string',
        },
        {
            field: 'sponsor_name',
            headerName: 'Sponsor',
            width: 150,
            sortable: false,
            type: 'string',
        },
        {
            field: 'subscription_tier',
            headerName: 'Subscription Level',
            width: 120,
            sortable: true,
            type: 'string',
            renderCell: (params) => {
                return params.row.subscription_tier;
            },
        },
        {
            field: 'uploads_this_month',
            headerName: 'Uploads this Month',
            width: 120,
            sortable: true,
            type: 'number',
            renderCell: (params) => {
                const uploadsPerMonth = params.row.uploads_per_month || {};
                const uploadsArray = Object.entries(uploadsPerMonth)
                    .map(([month, count]) => `${month}: ${count}`)
                    .join('\n');

                return (
                    <Tooltip
                        title={
                            <pre style={{ fontSize: '0.5rem' }}>{uploadsArray || 'no data'}</pre>
                        }
                        arrow
                    >
                        <span style={{ cursor: 'pointer' }}>{params.value}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'amount_past_due',
            headerName: 'Amount due',
            width: 120,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.amount_past_due || 0),
        },
        {
            field: 'total_commissions',
            headerName: 'Total Commissions',
            width: 150,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.total_commissions || 0),
        },
        {
            field: 'marketplace_commissions',
            headerName: 'Marketplace Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.marketplace_commissions || 0),
        },
        {
            field: 'pib_bonus_commissions',
            headerName: 'PIB Bonus Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.pib_bonus_commissions || 0),
        },
        {
            field: 'residual_commissions',
            headerName: 'Residual Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.residual_commissions || 0),
        },
        {
            field: 'check_match_commissions',
            headerName: 'Check Match Commissions',
            width: 160,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.check_match_commissions || 0),
        },
        {
            field: 'org_pod_subscriptions',
            headerName: 'Org Pod Subscriptions',
            width: 140,
            sortable: true,
            type: 'number',
        },
        {
            field: 'athlete_pod_subscriptions',
            headerName: 'Athlete Pod Subscriptions',
            width: 140,
            sortable: true,
            type: 'number',
        },
        {
            field: 'athlete_count',
            headerName: 'Athlete Count',
            width: 120,
            sortable: true,
            type: 'number',
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 100,
            sortable: true,
            type: 'string',
            renderCell: (params) => {
                const date = new Date(params.value);
                return date.toISOString().split('T')[0];
            },
        },
    ];

    const handleOrgSearch = async (event: React.FormEvent) => {
        setIsLoading(true);
        event.preventDefault();
        try {
            await getOrgSearchResults(searchParams);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getOrgSearchResults = async (params: any) => {
        const response = await getOrganizationsAndSubscriptionData(params);
        if (Array.isArray(response.data.data)) {
            const updatedOrgs = response.data.data.map((org: Organization) => {
                return {
                    ...org,
                };
            });
            setOrganizations(updatedOrgs);
            setTotalOrganizations(response.data.meta.total);
        } else {
            console.error('Unable to get organization search results');
        }
    };

    const handleOrgSort = async (model: GridSortModel) => {
        setIsLoading(true);
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField === 'subscription_tier') {
            sortField = 'subscription_tier_sort';
        }

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetOrganizationsAndSubscriptionDataParams['sort'],
        };

        try {
            await getOrgSearchResults(params);
        } catch (error) {
            console.error('Failed to fetch sorted organizations', error);
        } finally {
            setIsLoading(false);
        }
    };

    // @ts-ignore
    return (
        <div className="relative">
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                    }}
                >
                    <div className="bg-white rounded-lg p-4 shadow-lg flex flex-col items-center gap-2">
                        <CircularProgress size={80} />
                    </div>
                </div>
            )}
            <Box sx={{ margin: theme.spacing(0, 2) }}>
                <h2>Organization Metrics</h2>
                <section style={{ marginBottom: theme.spacing(2) }}>
                    <h4>Filter by Organization</h4>
                    <ResponsiveForm onSubmit={handleOrgSearch} noValidate>
                        <ResponsiveFormItem>
                            <Autocomplete
                                value={
                                    filterOrganizations.find(
                                        (org) => org.uuid === searchParams.organization_uuid,
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSearchParams({
                                        ...searchParams,
                                        organization_uuid: newValue ? newValue.uuid : '',
                                    });
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchFilterOrganizations(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                fullWidth
                                options={filterOrganizations}
                                getOptionLabel={(option) => `${option.name} - ${option.uuid}`}
                                renderInput={(params) => (
                                    <TextField {...params} label="Organizations" />
                                )}
                            />
                        </ResponsiveFormItem>
                        <ResponsiveFormItem>
                            <Button
                                type="submit"
                                size={'medium'}
                                variant="contained"
                                fullWidth={isNarrow}
                            >
                                Search Organizations
                            </Button>
                        </ResponsiveFormItem>
                    </ResponsiveForm>
                </section>
                <Box width="98%" maxHeight="600px" overflow="auto">
                    <DataGridPremium
                        rows={organizations}
                        columns={columns}
                        checkboxSelection={false}
                        sortingMode="server"
                        onSortModelChange={(model: GridSortModel) => handleOrgSort(model)}
                        getRowId={(row) => row.id}
                        style={{ height: '624px', marginBottom: '50px' }}
                        pagination
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={totalOrganizations}
                        paginationMode="server"
                        disableColumnMenu={true}
                        sx={{
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '0.6rem',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                fontSize: '0.6rem',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '0.6rem',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                fontSize: '0.6rem',
                            },
                        }}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default OrganizationsList;
