import { Box, Button, Autocomplete, TextField, Snackbar, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import {
    getOrgsForCoach,
    getIndividualsForCoach,
    getSubmissionsForCoach,
    SubmissionListParams,
    getAssignmentsForCoach,
    AssignmentListParams,
} from '../learn.api';
import { Organization, Individual, Submission, CalendarEventListItem } from '../../../utils';
import { OpenInNew } from '@mui/icons-material';
import SubmissionResultModal from './SubmissionResultModal';
import ViewAssessmentModal from './ViewAssessmentModal';

const SubmissionListDashboard = () => {
    // State management
    const [submissions, setSubmissions] = useState<Submission[]>([]);
    const [assignments, setAssignments] = useState<CalendarEventListItem[]>([]);
    const [submissionListOrganizations, setSubmissionListOrganizations] = useState<Organization[]>(
        [],
    );
    const [submissionListIndividuals, setSubmissionListIndividuals] = useState<Individual[]>([]);
    const [selectedSubmissionListOrg, setSelectedSubmissionListOrg] = useState<Organization | null>(
        null,
    );
    const [selectedSubmissionListIndividual, setSelectedSubmissionListIndividual] =
        useState<Individual | null>(null);
    const [assignmentListOrganizations, setAssignmentListOrganizations] = useState<Organization[]>(
        [],
    );
    const [assignmentListIndividuals, setAssignmentListIndividuals] = useState<Individual[]>([]);
    const [selectedAssignmentListOrg, setSelectedAssignmentListOrg] = useState<Organization | null>(
        null,
    );
    const [selectedAssignmentListIndividual, setSelectedAssignmentListIndividual] =
        useState<Individual | null>(null);
    const [loadingOrgs, setLoadingOrgs] = useState(false);
    const [loadingIndividuals, setLoadingIndividuals] = useState(false);
    const [submissionListPaginationModel, setSubmissionListPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [assignmentListPaginationModel, setAssignmentListPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [totalSubmissionRows, setTotalSubmissionRows] = useState(0);
    const [totalAssignmentRows, setTotalAssignmentRows] = useState(0);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [sortSubmissionsModel, setSortSubmissionsModel] = useState<any>([
        {
            field: 'submitted_at',
            sort: 'desc',
        },
    ]);
    const [sortAssignmentsModel, setSortAssignmentsModel] = useState<any>([
        {
            field: 'starts_at',
            sort: 'desc',
        },
    ]);
    const [formTitleForAssessment, setFormTitleForAssessment] = useState<string>('');
    const [formTitleForSubmission, setFormTitleForSubmission] = useState<string>('');
    const [isSubmissionResultModalOpen, setIsSubmissionResultModalOpen] = useState(false);
    const [selectedSubmissionResultId, setSelectedSubmissionResultId] = useState<string>('');
    const [isAssessmentFormModalOpen, setIsAssessmentFormModalOpen] = useState(false);
    const [selectedAssessmentId, setSelectedAssessmentId] = useState<string>('');

    const handleSortSubmissionModelChange = async (newModel: any) => {
        setSortSubmissionsModel(newModel);

        const params: SubmissionListParams = {
            page: submissionListPaginationModel.page + 1,
            per_page: submissionListPaginationModel.pageSize,
        };

        // Add filters if they exist
        if (selectedSubmissionListOrg) {
            params['filter[organization_uuid]'] = selectedSubmissionListOrg.uuid;
        } else if (selectedSubmissionListIndividual) {
            params['filter[individual_uuid]'] = selectedSubmissionListIndividual.uuid;
        }

        // Add sort parameter
        if (newModel.length > 0) {
            const { field, sort } = newModel[0];
            params.sort = sort === 'desc' ? `-${field}` : field;
        }

        try {
            const submissionsResponse = await getSubmissionsForCoach(params);
            setSubmissions(submissionsResponse.data.data);
            setTotalSubmissionRows(submissionsResponse.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch submissions', error);
            setSnackMessage('Failed to fetch submissions');
        }
    };

    const handleSortAssignmentModelChange = async (newModel: any) => {
        setSortAssignmentsModel(newModel);

        const params: AssignmentListParams = {
            page: assignmentListPaginationModel.page + 1,
            per_page: assignmentListPaginationModel.pageSize,
        };

        // Add filters if they exist
        if (selectedAssignmentListOrg) {
            params['filter[organization_uuid]'] = selectedAssignmentListOrg.uuid;
        } else if (selectedAssignmentListIndividual) {
            params['filter[individual_uuid]'] = selectedAssignmentListIndividual?.uuid;
        }

        // Add sort parameter
        if (newModel.length > 0) {
            const { field, sort } = newModel[0];
            params.sort = sort === 'desc' ? `-${field}` : field;
        }

        try {
            const assignmentsResponse = await getAssignmentsForCoach(params);
            setAssignments(assignmentsResponse.data.data);
            setTotalAssignmentRows(assignmentsResponse.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch assignments', error);
            setSnackMessage('Failed to fetch assignments');
        }
    };

    const submissionColumns = [
        {
            field: 'submitted_at',
            headerName: 'Submitted',
            width: 200,
            sortable: true,
            renderCell: (params: any) => {
                if (!params.row.submitted_at) {
                    return 'Not submitted yet';
                }
                const date = new Date(params.row.submitted_at * 1000);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            },
        },
        {
            field: 'individual_name',
            headerName: 'Athlete',
            width: 200,
            sortable: false,
            renderCell: (params: any) => params.row.individual?.name || '',
        },
        {
            field: 'formTitle',
            headerName: 'Assessment',
            width: 200,
            sortable: false,
            renderCell: (params: any) => (
                <Button
                    onClick={() => {
                        setSelectedAssessmentId(params.row.form?.uuid);
                        setIsAssessmentFormModalOpen(true);
                    }}
                >
                    {params.row.form?.title || 'N/A'}
                </Button>
            ),
        },
        {
            field: 'score',
            headerName: 'Score',
            width: 150,
            sortable: false,
            renderCell: (params: any) => {
                const score = params.row.score || 'N/A';
                return score === 'N/A' ? (
                    <Tooltip title="This is not a graded assessment">
                        <span>{score}</span>
                    </Tooltip>
                ) : (
                    <span>{score}</span>
                );
            },
        },
        {
            field: 'results',
            headerName: 'Test Results',
            width: 150,
            sortable: false,
            renderCell: (params: any) => (
                <Tooltip title="View athlete assessment results">
                    <Button
                        onClick={() => {
                            setSelectedSubmissionResultId(params.row.uuid);
                            setIsSubmissionResultModalOpen(true);
                        }}
                    >
                        <OpenInNew fontSize="small" style={{ color: 'black' }} />
                    </Button>
                </Tooltip>
            ),
        },
    ];

    const assignmentColumns = [
        {
            field: 'individual',
            headerName: 'Athlete',
            width: 200,
            sortable: true,
            renderCell: (params: any) => {
                return params.row.individual?.title || '';
            },
        },
        {
            field: 'formTitle',
            headerName: 'Assessment',
            width: 200,
            sortable: false,
            renderCell: (params: any) => (
                <Button
                    onClick={() => {
                        setSelectedAssessmentId(params.row.form?.source_id);
                        setIsAssessmentFormModalOpen(true);
                    }}
                >
                    {params.row.form?.title || 'N/A'}
                </Button>
            ),
        },
        {
            field: 'starts_at',
            headerName: 'Starts At',
            width: 200,
            sortable: true,
            renderCell: (params: any) => {
                console.log('starts at', params.row.starts_at);
                const date = new Date(params.row.starts_at * 1000);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            },
        },
        {
            field: 'ends_at',
            headerName: 'Ends At',
            width: 200,
            sortable: true,
            renderCell: (params: any) => {
                const date = new Date(params.row.starts_at * 1000);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            },
        },
        {
            field: 'started_at',
            headerName: 'Started At',
            width: 200,
            sortable: true,
            renderCell: (params: any) => {
                if (params.row.started_at) {
                    const date = new Date(params.row.started_at * 1000);
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    });
                } else {
                    return '';
                }
            },
        },
        {
            field: 'ended_at',
            headerName: 'Ended At',
            width: 200,
            sortable: true,
            renderCell: (params: any) => {
                if (params.row.ended_at) {
                    const date = new Date(params.row.ended_at * 1000);
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    });
                } else {
                    return '';
                }
            },
        },
    ];

    const loadSubmissions = async (params: SubmissionListParams) => {
        try {
            const submissionsResponse = await getSubmissionsForCoach(params);
            setSubmissions(submissionsResponse.data.data);
            setTotalSubmissionRows(submissionsResponse.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch submissions', error);
            setSnackMessage('Failed to fetch submissions');
        }
    };

    const loadAssignments = async (params: AssignmentListParams) => {
        try {
            const assignmentsResponse = await getAssignmentsForCoach(params);
            setAssignments(assignmentsResponse.data.data);
            setTotalAssignmentRows(assignmentsResponse.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch assignments', error);
            setSnackMessage('Failed to fetch assignments');
        }
    };

    useEffect(() => {
        loadSubmissions({
            page: 1,
            per_page: submissionListPaginationModel.pageSize,
            sort: '-submitted_at',
        }).then(() => {});
        loadAssignments({
            page: 1,
            per_page: assignmentListPaginationModel.pageSize,
            sort: '-starts_at',
        }).then(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmissionListPaginationModelChange = (newModel: any) => {
        setSubmissionListPaginationModel(newModel);
        const params: SubmissionListParams = {
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        };

        // Add any existing filters
        if (selectedSubmissionListOrg) {
            params['filter[organization_uuid]'] = selectedSubmissionListOrg.uuid;
        } else if (selectedSubmissionListIndividual) {
            params['filter[individual_uuid]'] = selectedSubmissionListIndividual.uuid;
        }

        // Add any existing sort
        if (sortSubmissionsModel.length > 0) {
            const { field, sort } = sortSubmissionsModel[0];
            params.sort = sort === 'desc' ? `-${field}` : field;
        }

        loadSubmissions(params).then(() => {});
    };

    const handleAssignmentListPaginationModelChange = (newModel: any) => {
        setAssignmentListPaginationModel(newModel);
        const params: AssignmentListParams = {
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        };

        // Add any existing filters
        if (selectedAssignmentListOrg) {
            params['filter[organization_uuid]'] = selectedAssignmentListOrg.uuid;
        } else if (selectedAssignmentListIndividual) {
            params['filter[individual_uuid]'] = selectedAssignmentListIndividual.uuid;
        }

        // Add any existing sort
        if (sortAssignmentsModel.length > 0) {
            const { field, sort } = sortAssignmentsModel[0];
            params.sort = sort === 'desc' ? `-${field}` : field;
        }

        loadAssignments(params).then(() => {});
    };

    const handleSubmissionsSubmit = async () => {
        const params: SubmissionListParams = {
            page: submissionListPaginationModel.page + 1,
            per_page: submissionListPaginationModel.pageSize,
        };

        if (selectedSubmissionListOrg) {
            params['filter[organization_uuid]'] = selectedSubmissionListOrg.uuid;
        } else if (selectedSubmissionListIndividual) {
            params['filter[individual_uuid]'] = selectedSubmissionListIndividual.uuid;
        }

        if (formTitleForSubmission) {
            params['filter[form_title]'] = formTitleForSubmission;
        }

        if (sortSubmissionsModel.length > 0) {
            const { field, sort } = sortSubmissionsModel[0];
            params.sort = sort === 'desc' ? `-${field}` : field;
        }

        try {
            const submissionsResponse = await getSubmissionsForCoach(params);
            setSubmissions(submissionsResponse.data.data);
            setTotalSubmissionRows(submissionsResponse.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch submissions', error);
            setSnackMessage('Failed to fetch submissions');
        }
    };

    const handleAssignmentsSubmit = async () => {
        const params: AssignmentListParams = {
            page: assignmentListPaginationModel.page + 1,
            per_page: assignmentListPaginationModel.pageSize,
        };

        if (selectedAssignmentListOrg) {
            params['filter[organization_uuid]'] = selectedAssignmentListOrg.uuid;
        } else if (selectedAssignmentListIndividual) {
            params['filter[individual_uuid]'] = selectedAssignmentListIndividual.uuid;
        }

        if (formTitleForAssessment) {
            params['filter[form_title]'] = formTitleForAssessment;
        }

        if (sortAssignmentsModel.length > 0) {
            const { field, sort } = sortAssignmentsModel[0];
            params.sort = sort === 'desc' ? `-${field}` : field;
        }

        try {
            const assignmentsResponse = await getAssignmentsForCoach(params);
            setAssignments(assignmentsResponse.data.data);
            setTotalAssignmentRows(assignmentsResponse.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch assignments', error);
            setSnackMessage('Failed to fetch assignments');
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmissionListSearchOrgs = useCallback(
        debounce(async (searchText: string) => {
            try {
                setLoadingOrgs(true);
                const response = await getOrgsForCoach({ 'filter[name]': searchText });
                setSubmissionListOrganizations(response.data.data);
            } catch (error) {
                console.error('Failed to fetch organizations', error);
                setSnackMessage('Failed to fetch organizations');
            } finally {
                setLoadingOrgs(false);
            }
        }, 500),
        [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmissionListSearchIndividuals = useCallback(
        debounce(async (searchText: string) => {
            try {
                setLoadingIndividuals(true);
                const response = await getIndividualsForCoach({ 'filter[name]': searchText });
                setSubmissionListIndividuals(response.data.data);
            } catch (error) {
                console.error('Failed to fetch individuals', error);
                setSnackMessage('Failed to fetch individuals');
            } finally {
                setLoadingIndividuals(false);
            }
        }, 500),
        [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedAssignmentListSearchOrgs = useCallback(
        debounce(async (searchText: string) => {
            try {
                setLoadingOrgs(true);
                const response = await getOrgsForCoach({ 'filter[name]': searchText });
                setAssignmentListOrganizations(response.data.data);
            } catch (error) {
                console.error('Failed to fetch organizations', error);
                setSnackMessage('Failed to fetch organizations');
            } finally {
                setLoadingOrgs(false);
            }
        }, 500),
        [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedAssignmentListSearchIndividuals = useCallback(
        debounce(async (searchText: string) => {
            try {
                setLoadingIndividuals(true);
                const response = await getIndividualsForCoach({ 'filter[name]': searchText });
                setAssignmentListIndividuals(response.data.data);
            } catch (error) {
                console.error('Failed to fetch individuals', error);
                setSnackMessage('Failed to fetch individuals');
            } finally {
                setLoadingIndividuals(false);
            }
        }, 500),
        [],
    );

    return (
        <Box style={{ margin: '2rem' }}>
            <ViewAssessmentModal
                open={isAssessmentFormModalOpen}
                onClose={() => setIsAssessmentFormModalOpen(false)}
                assessmentId={selectedAssessmentId}
            />
            <SubmissionResultModal
                open={isSubmissionResultModalOpen}
                onClose={() => setIsSubmissionResultModalOpen(false)}
                submissionId={selectedSubmissionResultId}
            />
            <Snackbar
                open={!!snackMessage}
                autoHideDuration={6000}
                message={snackMessage}
                onClose={() => setSnackMessage('')}
            />

            <h2>Assessment Form Assignments and Submissions</h2>

            <section style={{ marginBottom: '4rem' }}>
                <h4>Filter Assignments</h4>
                <Box style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Autocomplete
                        sx={{ width: 300 }}
                        size="small"
                        options={assignmentListOrganizations}
                        loading={loadingOrgs}
                        value={selectedAssignmentListOrg}
                        onChange={(_, newValue) => setSelectedAssignmentListOrg(newValue)}
                        getOptionLabel={(option) => option.name}
                        disabled={!!selectedAssignmentListIndividual}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Organization"
                                onChange={(e) => debouncedAssignmentListSearchOrgs(e.target.value)}
                            />
                        )}
                    />

                    <Autocomplete
                        sx={{ width: 300 }}
                        size="small"
                        options={assignmentListIndividuals}
                        loading={loadingIndividuals}
                        value={selectedAssignmentListIndividual}
                        onChange={(_, newValue) => setSelectedAssignmentListIndividual(newValue)}
                        getOptionLabel={(option) =>
                            option.managing_user
                                ? `${option.name} (${option.managing_user.user_name})`
                                : option.name
                        }
                        disabled={!!selectedAssignmentListOrg}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Athlete"
                                onChange={(e) =>
                                    debouncedAssignmentListSearchIndividuals(e.target.value)
                                }
                            />
                        )}
                    />

                    <TextField
                        sx={{ width: 300 }}
                        size="small"
                        label="Assessment Title"
                        value={formTitleForAssessment}
                        onChange={(e) => setFormTitleForAssessment(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleAssignmentsSubmit}>
                        Search
                    </Button>
                </Box>

                <Box width="100%" height="600px">
                    <h4>Assignments</h4>
                    <DataGrid
                        rows={assignments}
                        columns={assignmentColumns}
                        pagination
                        paginationMode="server"
                        rowCount={totalAssignmentRows}
                        paginationModel={assignmentListPaginationModel}
                        onPaginationModelChange={handleAssignmentListPaginationModelChange}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        getRowId={(row) => row.uuid || ''}
                        sortingMode="server"
                        sortModel={sortAssignmentsModel}
                        onSortModelChange={handleSortAssignmentModelChange}
                        disableColumnMenu
                        loading={false}
                    />
                </Box>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter Submissions</h4>
                <Box style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Autocomplete
                        sx={{ width: 300 }}
                        size="small"
                        options={submissionListOrganizations}
                        loading={loadingOrgs}
                        value={selectedSubmissionListOrg}
                        onChange={(_, newValue) => setSelectedSubmissionListOrg(newValue)}
                        getOptionLabel={(option) => option.name}
                        disabled={!!selectedSubmissionListIndividual}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Organization"
                                onChange={(e) => debouncedSubmissionListSearchOrgs(e.target.value)}
                            />
                        )}
                    />

                    <Autocomplete
                        sx={{ width: 300 }}
                        size="small"
                        options={submissionListIndividuals}
                        loading={loadingIndividuals}
                        value={selectedSubmissionListIndividual}
                        onChange={(_, newValue) => setSelectedSubmissionListIndividual(newValue)}
                        getOptionLabel={(option) =>
                            option.managing_user
                                ? `${option.name} (${option.managing_user.user_name})`
                                : option.name
                        }
                        disabled={!!selectedSubmissionListOrg}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Athlete"
                                onChange={(e) =>
                                    debouncedSubmissionListSearchIndividuals(e.target.value)
                                }
                            />
                        )}
                    />
                    <TextField
                        sx={{ width: 300 }}
                        size="small"
                        label="Assessment Title"
                        value={formTitleForSubmission}
                        onChange={(e) => setFormTitleForSubmission(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleSubmissionsSubmit}>
                        Search
                    </Button>
                </Box>

                <Box width="100%" height="600px">
                    <h4>Submissions</h4>
                    <DataGrid
                        rows={submissions}
                        columns={submissionColumns}
                        pagination
                        paginationMode="server"
                        rowCount={totalSubmissionRows}
                        paginationModel={submissionListPaginationModel}
                        onPaginationModelChange={handleSubmissionListPaginationModelChange}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        getRowId={(row) => row.uuid || ''}
                        sortingMode="server"
                        sortModel={sortSubmissionsModel}
                        onSortModelChange={handleSortSubmissionModelChange}
                        disableColumnMenu
                        loading={false}
                    />
                </Box>
            </section>
        </Box>
    );
};

export default SubmissionListDashboard;
